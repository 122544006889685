.header {
  position: fixed;
  width: 100%;
  height: var(--size-hd);
  top: 0;
  left: 0;
  z-index: 2002;
  transition: all 0.5s ease;

  @media screen and (max-width: 1200px) {
    height: var(--size-hd-tl);
  }

  &.trans {
    box-shadow: 0 0 0.1rem rgba($color: #fff, $alpha: 0.5);
    .header-bottom {
      background: transparent;

      @media screen and (max-width: 1200px) {
        background: $color-white;
      }
    }

    &.bg {
      .header-bottom {
        background: $color-white;
      }

      .header-search-ip {
        input {
          color: $color-pri;
        }
      }

      .header-search-ex {
        i {
          color: $color-pri;
        }
        @include hover {
          i {
            color: $color-second;
          }
        }
      }

      .header-logo {
        img {
          filter: unset;
        }
      }

      .menu-item {
        &.current-menu-item {
          &::before {
            background: $color-pri;
          }
          .menu-link {
            color: $color-white;
          }
        }

        @include hover {
          &::before {
            background: $color-pri;
          }

          .menu-link {
            color: $color-white;
          }
        }
      }

      .menu-link {
        color: $color-pri;
      }

      .header-ic {
        @include hover {
          img {
            filter: brightness(0) saturate(100%) invert(70%) sepia(83%) saturate(569%) hue-rotate(349deg)
              brightness(101%) contrast(99%);
          }
        }

        img {
          filter: unset;
        }
      }
    }

    .header-search-ip {
      input {
        color: $color-white;
      }
    }

    .header-search-ex {
      i {
        color: $color-white;
      }
      @include hover {
        i {
          color: $color-second;
        }
      }
    }

    .header-logo {
      img {
        filter: brightness(0) saturate(100%) invert(98%) sepia(3%) saturate(317%) hue-rotate(282deg) brightness(114%)
          contrast(100%);

        @media screen and (max-width: 1200px) {
          filter: unset;
        }
      }
    }

    .menu-item {
      &.current-menu-item {
        &::before {
          background: $color-second;
        }
        .menu-link {
          color: $color-pri;
        }
      }

      @include hover {
        &::before {
          background: $color-second;
        }

        .menu-link {
          color: $color-pri;
        }
      }
    }

    .menu-link {
      color: $color-white;
    }

    .header-ic {
      @include hover {
        img {
          filter: brightness(0) saturate(100%) invert(70%) sepia(83%) saturate(569%) hue-rotate(349deg) brightness(101%)
            contrast(99%);
        }
      }

      img {
        filter: brightness(0) saturate(100%) invert(98%) sepia(3%) saturate(317%) hue-rotate(282deg) brightness(114%)
          contrast(100%);

        @media screen and (max-width: 1200px) {
          filter: unset;
        }
      }
    }
  }

  &-ic {
    width: 90%;
    // height: 4rem;
    height: 100%;
    display: flex;
    align-items: center;
    // justify-content: center;
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 2%;
    transform: translate(100%, -50%);
    transition: all 0.6s ease-in-out 0.2s;
    flex-shrink: 0;
    i {
      @include fz-20;
    }

    @include hover {
      img {
        filter: brightness(0) saturate(100%) invert(70%) sepia(83%) saturate(569%) hue-rotate(349deg) brightness(101%)
          contrast(99%);
      }
    }

    img {
      width: 2.4rem;
      height: 2.4rem;
    }

    @media screen and (max-width: 1200px) {
      width: 4rem;
      right: 0;
      transform: unset;
      position: static;
      justify-content: flex-end;
      i {
        font-size: 2.4rem !important;
        font-weight: 400 !important;
      }

      img {
        width: 2.4rem;
        height: 2.4rem;
      }
    }
  }

  &-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    // column-gap: 1.4rem;
    height: 100%;
    // justify-content: space-between;
    width: 100%;

    transition: all 0.3s ease;

    // &-content {
    //   display: flex;
    //   align-items: center;
    //   column-gap: 4rem;

    //   height: 100%;

    //   @media screen and (max-width: 1200px) {
    //     column-gap: 1.6rem;
    //   }
    // }
  }

  @keyframes hienSearch {
    0% {
      opacity: 0;
      clip-path: inset(0 100% 0 0);
    }

    50% {
      opacity: 0.5;
      clip-path: inset(0 50% 0 0);
    }

    100% {
      opacity: 0.5;
      clip-path: inset(0 0 0 0);
    }
  }

  @keyframes anSearch {
    0% {
      opacity: 1;
      clip-path: inset(0 0 0 0);
    }

    50% {
      opacity: 0.5;
      clip-path: inset(0 50% 0 0);
    }

    100% {
      opacity: 0;
      clip-path: inset(0 100% 0 0);
    }
  }

  &-search {
    position: absolute;
    width: 90%;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    pointer-events: none;

    transform: translateX(100%);
    transition: all 0.6s ease-in-out;

    &-wrap {
      display: flex;
      justify-content: space-between;
      height: 100%;
    }

    form {
      height: 100%;
    }

    &-ip {
      width: 95%;
      border-left: 0.2rem solid #e6e6e6;
      border-right: 0.2rem solid #e6e6e6;
      display: flex;
      align-items: center;
      input {
        width: 100%;
        padding: 0rem 4rem;
        color: $color-text;
        font-family: var(--font-sec);

        &::placeholder {
          color: $color-gray;
          font-family: vảr(--font-sec);
        }
      }
    }

    &-ex {
      flex: 1;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding: 1.2rem;

      @include hover {
        i {
          color: $color-second;
        }
      }

      i {
        @include fz-20;
        color: $color-text;
      }
    }

    @media screen and (max-width: 1200px) {
      display: none;
    }
  }

  &.actived {
    height: 4.8rem;
    .header-bottom {
      transform: translateY(-200%);
      opacity: 0;
    }

    .mega-menu {
      top: 12rem;
    }

    @media screen and (max-width: 1200px) {
      height: 7rem;
    }

    @media screen and (max-width: 1200px) {
      & ~ .menu-mb {
        top: 7rem;
        height: calc(100% - 7rem);
      }
    }
  }

  &-top {
    height: 4.8rem;
    width: 100%;
    background: $color-pri;
    position: relative;
    z-index: 1;
    &-wrap {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-lf {
      &-wrap {
        display: flex;
        column-gap: 2.4rem;
      }
    }

    @media screen and (max-width: 1200px) {
      display: none;
    }
  }

  &-bottom {
    background: $color-white;
    width: 100%;
    height: 100%;
    transition: all 0.5s ease;

    @media screen and (max-width: 1200px) {
      border-bottom: 0.1rem solid rgba($color: $color-pri, $alpha: 0.3);
    }
    &-wrap {
      display: flex;
      justify-content: space-between;
      height: 100%;
      position: relative;
      align-items: center;
    }
  }

  &-nav {
    height: 100%;
    display: flex;
    align-items: center;
    column-gap: 1.2rem;
    display: flex;
    z-index: 1;
    position: relative;
    opacity: 1;
    transition: all 0.6s ease-in-out 0.4s;
    pointer-events: visible;

    &.none {
      transform: translateX(-100%);
      opacity: 0;
      pointer-events: none;
      transition: all 0.6s ease-in-out;
    }

    @media screen and (max-width: 1200px) {
      .menu-list {
        display: none;
      }
    }
  }

  &-btn {
    &-search {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &-logo {
    max-width: 9.3rem;

    height: auto;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    position: relative;
    overflow: hidden;
    z-index: 2;

    &::before {
      content: "";
      top: 50%;
      left: -120%;
      position: absolute;
      transform: translate(-50%, -50%) rotate(-55deg);
      width: 250%;
      height: 50%;
      background: rgba($color: #fff, $alpha: 0.7);
      z-index: 1;
      opacity: 0.6;
    }

    @include hover {
      &::before {
        left: 180%;
        opacity: 1;
        transition:
          left 0.5s linear,
          top 0.5s linear,
          opacity 0.3s ease;
      }

      img {
        transform: scale(0.9);
      }
    }

    img {
      width: 100%;
      height: 100%;
      transition: all 0.3s ease;
      object-fit: contain;
    }

    @media screen and (max-width: 1200px) {
      max-width: 7.4rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    @media screen and (max-width: 600px) {
      max-width: 6.8rem;
    }

    // @media screen and (max-width: 460px) {
    //   max-width: 15rem;
    // }

    // @media screen and (max-width: 320px) {
    //   max-width: 13rem;
    // }
  }

  &-mobi {
    width: 3rem;
    height: 2.2rem;
    position: relative;
    display: none;
    cursor: pointer;
    @media screen and (max-width: 1200px) {
      display: block;
    }

    &.actived {
      .line {
        &:first-child {
          top: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
          width: 100%;
          transition:
            top 0.3s linear,
            transform 0.3s linear 0.5s;
        }

        &:nth-child(2) {
          opacity: 0;
          transition: opacity 0.1s ease 0.3s;
        }

        &:last-child {
          bottom: 50%;
          transform: translate(-50%, 50%) rotate(-45deg);
          width: 100%;
          transition:
            bottom 0.3s linear,
            transform 0.3s linear 0.5s;
        }
      }
    }

    .line {
      width: 100%;
      display: block;
      height: 0.3rem !important;
      background-color: $color-pri;
      transform: translateX(-50%);
      border-radius: 100rem;
      overflow: hidden;
      position: absolute;

      &:first-child {
        top: 0;
        left: 50%;
        width: 100%;

        transition:
          top 0.3s linear 0.5s,
          transform 0.3s linear 0s;
      }

      &:nth-child(2) {
        top: 50%;
        left: 0;
        width: 100%;
        transform: translate(0%, -50%);
        transition: opacity 0.1s ease 0.3s;
      }

      &:last-child {
        width: 100%;
        bottom: 0%;
        left: 50%;
        transition:
          bottom 0.3s linear 0.5s,
          transform 0.3s linear 0s;
      }
    }
  }

  &-box {
    width: 10%;
    flex-shrink: 0;
    height: 100%;
    display: flex;
    align-items: center;
    column-gap: 6rem;
    &.showed {
      .header-ic {
        transform: translate(0%, -50%);
        opacity: 0;
        transition: all 0.6s ease-in-out 0.2s;
      }

      .header-search {
        pointer-events: visible;
        transition: all 0.6s ease-in-out 0.4s;
        transform: translateX(0%);
        opacity: 1;
      }
    }

    @media screen and (max-width: 1400px) {
      column-gap: 3rem;
    }

    @media screen and (max-width: 1200px) {
      column-gap: 1.4rem;
      flex-direction: row-reverse;
    }

    @media screen and (max-width: 460px) {
      column-gap: 0.8rem;
    }

    .btn {
      flex-shrink: 0;
      width: fit-content;
      @media screen and (max-width: 1200px) {
        min-width: unset;
      }

      &-ic {
        display: none;
        @media screen and (max-width: 500px) {
          display: flex;
        }
      }

      &-text {
        @media screen and (max-width: 500px) {
          display: none;
        }
      }

      @media screen and (max-width: 500px) {
        padding: 1.2rem 1.4rem;
      }
    }
  }
}

.modal-search {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: transparent;
  transition: all 0.3s linear;
  visibility: hidden;
  pointer-events: none;
  user-select: none;
  opacity: 0;
  &.showed {
    visibility: visible;
    pointer-events: visible;
    opacity: 1;
  }
}
