.contact {
    width: 100%;
    // height: calc(100vw * 896 / 1440);
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
    clip-path: inset(0 0 0);
    // @media screen and (max-width: 1200px) {
    //     height: calc(100vw * 571 / 744);
    // }

    // @media screen and (max-width: 600px) {
    //     height: calc(100vw * 713 / 375);
    // }

    &::before {
        content: "";
        position: absolute;
        inset: 0;
        background: rgba($color: #000000, $alpha: 0.5);
        display: none;
        width: 100%;
        height: 100%;
        @media screen and (max-width: 600px) {
            display: block;
        }
    }

    &_bg {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 0% 0;
            object-position: 50%;

            // @media screen and (max-width: 1200px) {
            //     position: absolute;
            //     width: 100%;
            //     height: 100%;
            // }

            @media screen and (max-width: 1024px) {
                object-position: 75%;
            }

            // @media screen and (max-width: 800px) {
            //     object-position: 80%;
            // }

            @media screen and (max-width: 700px) {
                object-position: 70%;
            }

            @media screen and (max-width: 600px) {
                object-position: 40%;
            }
        }
    }

    &_wrap {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 10rem 0;
        min-height: calc(100vh - var(--size-hd));

        @media screen and (max-width: 1200px) {
            min-height: calc(100vh - var(--size-hd-tl));
        }
    }

    &_content {
        position: relative;
        z-index: 1;
        width: calc(100% * 5 / 12);

        @media screen and (max-width: 1024px) {
            width: calc(100% * 6 / 12 + 2.4rem);
        }

        @media screen and (max-width: 700px) {
            width: calc(100% * 7 / 12 + 2.4rem);
        }

        @media screen and (max-width: 600px) {
            width: 100%;
        }

        .title-sm3 {
            text-align: end;

            @media screen and (max-width: 1200px) {
                font-size: 3.6rem;
            }

            @media screen and (max-width: 600px) {
                text-align: start;
                font-size: 3.2rem;
            }

            @media screen and (max-width: 360px) {
                text-align: start;
                font-size: 2.6rem;
            }
        }

        .note-text {
            text-align: end;
            margin-bottom: 1.2rem;

            @media screen and (max-width: 1200px) {
                font-size: 1.4rem;
            }

            @media screen and (max-width: 600px) {
                text-align: start;
                color: $color-white;
                margin-bottom: 0.4rem;
            }

            @media screen and (max-width: 460px) {
                text-align: start;
                color: $color-white;
                font-size: 1.2rem;
            }
        }
    }

    &_list {
        display: flex;
        flex-direction: column;
        row-gap: 0.5rem;
    }

    &_item {
    }

    &_link {
        text-align: end;
        display: block;
        @media screen and (max-width: 1200px) {
            font-size: 1.4rem;
        }

        @media screen and (max-width: 600px) {
            text-align: start;
            color: $color-white;
        }

        @media screen and (max-width: 460px) {
            text-align: start;
            color: $color-white;
            font-size: 1.2rem;
        }
    }
}

.form-item {
    &-lb {
    }

    &-ip {
        width: 100%;
        height: 4rem;
        background: $color-gray2 !important;
        @include fz-14;
        display: flex;
        align-items: center;
        padding: 0.8rem 1.2rem !important;
        color: $color-text;
        border: 0.1rem solid transparent !important;
        transition: all 0.3s linear;
        &:focus {
            border-color: $color-text !important;
        }

        @media screen and (max-width: 800px) {
            height: 3.8rem;
        }

        @media screen and (max-width: 460px) {
            @include fz-12;
        }

        &.cus {
            background: transparent !important;
            color: $color-white;
            border: none !important;
        }

        &::placeholder {
            color: $color-gray;
        }
    }

    &-tx {
        width: 100%;
        min-height: 12rem;
        background: $color-gray2 !important;
        @include fz-14;
        display: flex;
        align-items: center;
        padding: 0.8rem 1.2rem !important;
        color: $color-text;
        border: 0.1rem solid transparent !important;
        transition: all 0.3s linear;
        &:focus {
            border-color: $color-text !important;
        }

        @media screen and (max-width: 800px) {
            height: 3.8rem;
        }

        @media screen and (max-width: 460px) {
            @include fz-12;
        }

        &.cus {
            background: transparent !important;
            color: $color-white;
            border: none !important;
        }

        &::placeholder {
            color: $color-gray;
        }
    }
}

.popup_main {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 4000;
    width: 100%;
    max-width: 60rem;
    background: $color-gray2;
    padding: 14rem 13.2rem;
    transition: all 0.3s linear;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;

    @media screen and (max-width: 800px) {
        max-width: 46.4rem;
        padding: 14rem 12rem;
    }

    @media screen and (max-width: 600px) {
        max-width: 32.5rem;
        padding: 11.8rem 5rem;
        width: 95%;
    }

    &.showed {
        opacity: 1;
        pointer-events: visible;
        visibility: visible;

        & ~ .popup_main--modal {
            visibility: visible;
            pointer-events: visible;
            opacity: 1;
        }
    }

    &--wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 0.2rem;

        .title-sm4 {
            text-align: center;

            @media screen and (max-width: 1200px) {
                font-size: 3.2rem;
            }

            @media screen and (max-width: 460px) {
                font-size: 2.4rem;
            }
        }

        .note-text {
            text-align: center;

            @media screen and (max-width: 800px) {
                font-size: 1.2rem;
            }
        }
    }

    &--ic {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 4rem;
        height: 4rem;

        @media screen and (max-width: 800px) {
            width: 3.2rem;
            height: 3.2rem;
        }

        @media screen and (max-width: 460px) {
            width: 3.6rem;
            height: 3.6rem;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &--exits {
        position: absolute;
        top: 2rem;
        right: 2rem;
        width: 2rem;
        height: 2rem;
        cursor: pointer;
        @media screen and (max-width: 800px) {
            top: 1rem;
            right: 1rem;
        }

        i {
            font-size: 2.4rem;
        }
    }
}

.popup_main--modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3999;
    background-color: rgba($color: #000000, $alpha: 0.5);
    transition: all 0.3s linear;
    visibility: hidden;
    pointer-events: none;
    user-select: none;
    opacity: 0;
}

.popup-form {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 4000;
    max-width: 60rem;
    width: 100%;
    background: #f2f2f2;
    padding: 4rem 10rem;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    transition: all 0.3s linear;

    &.showed {
        opacity: 1;
        pointer-events: visible;
        visibility: visible;

        & ~ .popup-form-modal {
            visibility: visible;
            pointer-events: visible;
            opacity: 1;
        }
    }

    @media screen and (max-width: 800px) {
        max-width: 46.4rem;
        padding: 4rem 3.2rem;
    }

    @media screen and (max-width: 600px) {
        max-width: 32.8rem;
        padding: 4rem 2.4rem;
        width: 95%;
    }

    &-wrap {
        display: flex;
        flex-direction: column;
        row-gap: 1.6rem;

        @media screen and (max-width: 600px) {
            row-gap: 1.2rem;
        }
    }

    &-title {
        .title-mn {
            text-align: center;

            @media screen and (max-width: 800px) {
                font-size: 2rem;
            }

            @media screen and (max-width: 460px) {
                font-size: 1.8rem;
            }
        }
    }

    &-list {
        display: flex;
        flex-direction: column;
        row-gap: 0.4rem;
        margin-bottom: 1.6rem;
        max-height: 50vh;
        overflow-y: auto;
    }

    &-ex {
        position: absolute;
        top: 2rem;
        right: 2rem;
        width: 2rem;
        height: 2rem;
        cursor: pointer;
        @media screen and (max-width: 800px) {
            top: 1rem;
            right: 1rem;
        }

        i {
            font-size: 2.4rem;
        }
    }

    &-content {
        .btn {
            margin: auto;
        }
    }
}

.popup-form-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3999;
    background-color: rgba($color: #000000, $alpha: 0.5);
    transition: all 0.3s linear;
    visibility: hidden;
    pointer-events: none;
    user-select: none;
    opacity: 0;
}
