.banner-main {
    position: relative;
    overflow: hidden;
    margin-bottom: var(--size-pd);

    @media screen and (max-width: 1200px) {
        margin-bottom: var(--size-pd-tl);
    }

    @media screen and (max-width: 600px) {
        margin-bottom: var(--size-pd-mb);
    }

    &-bg {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
    }

    &-img {
        width: 100%;
        height: 100%;
        animation: nhoDan 1.2s linear;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &-title {
        padding-top: 17.6rem;
        padding-bottom: 17.6rem;

        @media screen and (max-width: 1200px) {
            padding-top: 12.8rem;
            padding-bottom: 12.8rem;
        }

        @media screen and (max-width: 600px) {
            padding-top: 8.4rem;
            padding-bottom: 8.4rem;
        }

        @media screen and (max-width: 360px) {
            padding-top: 6.4rem;
            padding-bottom: 6.4rem;
        }

        .title-sm3 {
            position: relative;
            z-index: 2;
            clip-path: inset(0 100% 0 0);
            animation: luotChu 4s linear 1.2s forwards;

            @keyframes luotChu {
                0% {
                    clip-path: inset(0 100% 0 0);
                }
                100% {
                    clip-path: inset(0 0 0 0);
                }
            }
        }
    }
}

.banner-ct {
    width: 100%;
    padding-bottom: var(--size-pd);

    @media screen and (max-width: 1200px) {
        padding-bottom: var(--size-pd-tl);
    }

    @media screen and (max-width: 600px) {
        padding-bottom: var(--size-pd-mb);
    }

    &-bg {
        width: 100%;
        overflow: hidden;
    }

    @keyframes nhoDan {
        0% {
            transform: scale(1.3);
            opacity: 0;
        }

        50% {
            transform: scale(1.15);
            opacity: 0.5;
        }

        100% {
            transform: scale(1);
            opacity: 1;
        }
    }

    &-img {
        width: 100%;
        height: calc(100vw * 280 / 1440);

        @media screen and (max-width: 1200px) {
            height: calc(100vw * 160 / 744);
        }

        @media screen and (max-width: 600px) {
            height: calc(100vw * 150 / 375);
        }

        animation: nhoDan 1.2s linear;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.banner-post {
    position: relative;
    overflow: hidden;
    min-height: 64rem;
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: var(--size-pd);

    @media screen and (max-width: 1200px) {
        margin-bottom: var(--size-pd-tl);
    }

    @media screen and (max-width: 800px) {
        align-items: flex-end;
        min-height: calc(100vh - var(--size-hd-tl));
    }

    @media screen and (max-width: 600px) {
        margin-bottom: var(--size-pd-mb);
        // min-height: 71.9rem;
    }

    @media screen and (max-width: 360px) {
        margin-bottom: var(--size-pd-mb);
        // min-height: 60rem;
    }
    &-bg {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &.left {
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 50%;
            height: 100%;
            background: linear-gradient(
                90deg,
                rgba(0, 0, 0, 0.67) 0%,
                rgba(0, 0, 0, 0.53) 77.6%,
                rgba(0, 0, 0, 0) 100%
            );
            z-index: 1;

            @media screen and (max-width: 800px) {
                width: 100%;
                background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0) 100%);
            }
        }

        .banner-post-wrap {
            justify-content: start;

            @media screen and (max-width: 800px) {
                justify-content: center;
            }
        }
    }

    &.right {
        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 50%;
            height: 100%;
            background: linear-gradient(
                270deg,
                rgba(0, 0, 0, 0.86) 0%,
                rgba(0, 0, 0, 0.52) 68.23%,
                rgba(0, 0, 0, 0) 100%
            );

            @media screen and (max-width: 800px) {
                width: 100%;
                background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0) 100%);
            }
        }

        .banner-post-wrap {
            justify-content: end;

            @media screen and (max-width: 800px) {
                justify-content: center;
            }
        }
    }

    &.normal {
        .banner-post-wrap {
            justify-content: start;

            @media screen and (max-width: 800px) {
                justify-content: center;
            }
        }
    }

    &.trans {
        .banner-post-wrap {
            justify-content: end;

            @media screen and (max-width: 800px) {
                justify-content: center;
            }
        }
    }

    &-wrap {
        display: flex;

        @media screen and (max-width: 800px) {
            padding: 2.4rem 0;
        }
    }

    &-content {
        display: flex;
        flex-direction: column;
        row-gap: 0.4rem;
        width: calc(100% * 6 / 12);
        position: relative;
        z-index: 2;

        @media screen and (max-width: 800px) {
            width: 100%;
        }
    }

    &-text {
        .note-text {
            @media screen and (max-width: 800px) {
                font-size: 1.4rem;
            }

            @media screen and (max-width: 460px) {
                font-size: 1.2rem;
            }
        }
    }
}

.banner_home {
    position: relative;
    width: 100%;
    overflow: hidden;

    margin-bottom: var(--size-pd);

    @media screen and (max-width: 1200px) {
        margin-bottom: var(--size-pd-tl);
    }

    @media screen and (max-width: 600px) {
        margin-bottom: var(--size-pd-mb);
    }

    &--list {
        position: relative;
        .swiper-slide {
            height: auto;
        }

        .swiper-slide-active {
            .banner_home--content {
                .title-sm3 {
                    transition: all 1s linear 0.5s;
                    clip-path: inset(0 0 0 0);
                }
            }

            .banner_home--btn {
                opacity: 1;
                transition: all 0.5s linear 1.5s;
            }
        }

        .swiper-pagination {
            position: absolute;
            bottom: 2.8rem;
            width: fit-content;

            left: 50%;
            transform: translateX(-50%);

            @media screen and (max-width: 800px) {
                bottom: 3.6rem;
                right: 2.4rem;
                left: unset;
                transform: unset;
            }

            @media screen and (max-width: 600px) {
                bottom: 2.8rem;
                right: 2.4rem;
                left: unset;
                transform: unset;
            }

            @media screen and (max-width: 360px) {
                bottom: 1.2rem;
                right: unset;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    &--item {
        height: 100%;
        // min-height: 89.6rem;
        min-height: calc(100vh - var(--size-hd));
        @media screen and (max-width: 1200px) {
            // min-height: 106.9rem;
            min-height: calc(100vh - var(--size-hd-tl));
        }

        @media screen and (max-width: 600px) {
            // min-height: 71.9rem;
        }

        @media screen and (max-width: 360px) {
            // min-height: 60rem;
        }
    }

    &--wrap {
        position: relative;
        z-index: 2;
        height: 100%;
        display: flex;
        align-items: center;

        @media screen and (max-width: 800px) {
            align-items: end;
        }
    }

    &--content {
        padding: 10rem 0;
        display: flex;
        flex-direction: column;
        row-gap: 1.6rem;
        max-width: 35.7rem;
        width: 100%;

        @media screen and (max-width: 1200px) {
            max-width: 32rem;
        }

        @media screen and (max-width: 800px) {
            max-width: 48rem;
            padding: 10rem 0 3.6rem;
            row-gap: 1.2rem;
        }

        @media screen and (max-width: 600px) {
            max-width: 48rem;
            padding: 10rem 0 2.4rem;
        }

        @media screen and (max-width: 460px) {
            max-width: 30rem;
            padding: 10rem 0 2.4rem;
            row-gap: 0.8rem;
        }

        @media screen and (max-width: 360px) {
            max-width: 100%;
            padding: 10rem 0 4rem;
            row-gap: 0.8rem;
        }

        .title-sm3 {
            clip-path: inset(0 100% 0 0);
            transition: all 1s linear;

            @media screen and (max-width: 1200px) {
                font-size: 3.6rem;
            }

            @media screen and (max-width: 460px) {
                font-size: 2.4rem;
            }

            @media screen and (max-width: 360px) {
                font-size: 2rem;
                text-align: center;
            }
        }
    }

    &--btn {
        display: flex;
        column-gap: 1.6rem;
        opacity: 0;
        transition: all 0.5s linear;

        @media screen and (max-width: 360px) {
            justify-content: center;
        }
    }

    &--bg {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        z-index: -1;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
}
