.product {
    padding-top: var(--size-pd);
    padding-bottom: var(--size-pd);

    @media screen and (max-width: 1200px) {
        padding-top: var(--size-pd-tl);
    }

    @media screen and (max-width: 600px) {
        padding-top: var(--size-pd-mb);
    }

    .title-sm2 {
    }

    &_title {
        display: flex;
        align-items: center;
        column-gap: 1.2rem;
        justify-content: space-between;
        margin-bottom: 2rem;

        @media screen and (max-width: 1024px) {
            margin-bottom: 2.4rem;
        }

        @media screen and (max-width: 600px) {
            margin-bottom: 1.6rem;
        }
    }

    &_btn--filter {
        width: 2.4rem;
        height: 2.4rem;
        cursor: pointer;
        // display: flex;
        align-items: center;
        justify-content: center;
        display: none;

        @media screen and (max-width: 1024px) {
            display: flex;
        }

        @media screen and (max-width: 600px) {
            width: 2rem;
            height: 2rem;
        }

        img {
            width: 100%;
            height: 100%;
        }
    }

    &_wrap {
    }

    &_check {
        &--list {
            display: flex;
            flex-direction: column;
            row-gap: 0.8rem;
        }

        &--item {
            display: flex;
            column-gap: 0.6rem;
            cursor: pointer;
            input {
                display: none;

                &:checked {
                    & ~ .box {
                        border-color: $color-second;

                        &::before {
                            opacity: 1;
                        }
                    }

                    & ~ .note-sm {
                        color: $color-pri !important;
                    }
                }
            }

            .box {
                width: 2rem;
                height: 2rem;
                border: 0.2rem solid $color-gray2;
                border-radius: 0.4rem;
                cursor: pointer;
                position: relative;
                transition: all 0.3s linear;
                flex-shrink: 0;
                cursor: pointer;
                transform: translateY(0.1rem);
                @media screen and (max-width: 500px) {
                    width: 1.6rem;
                    height: 1.6rem;
                }

                &::before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background-image: url(../assets/images/check.svg);
                    width: 1.8rem;
                    height: 1.8rem;
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                    transition: all 0.3s linear;
                    opacity: 0;

                    @media screen and (max-width: 500px) {
                        width: 1.4rem;
                        height: 1.4rem;
                    }
                }
            }

            .note-sm {
                color: $color-gray;
                font-family: var(--font-sec);
                transition: all 0.3s linear;
            }
        }
    }

    &_color {
        &--list {
            display: flex;
            flex-wrap: wrap;
            column-gap: 0.8rem;
            max-width: 90%;
            width: 100%;
            row-gap: 0.8rem;
            // margin-left: -0.4rem;
            // margin-right: -0.4rem;
            overflow: hidden;

            @media screen and (max-width: 1200px) {
                max-width: none;
            }
        }

        &--item {
            width: 3.2rem;
            height: 3.2rem;
            // padding-left: 0.4rem;
            // padding-right: 0.4rem;
            cursor: pointer;
            flex-shrink: 0;

            @media screen and (max-width: 800px) {
                width: 2.4rem;
                height: 2.4rem;
            }

            @media screen and (max-width: 360px) {
                width: 2rem;
                height: 2rem;
            }

            // display: flex;
            // justify-content: center;
            // align-items: center;

            input {
                &:checked {
                    & ~ .box {
                        border-color: $color-second;

                        &::before {
                            opacity: 1;
                        }

                        &::after {
                            opacity: 1;
                        }
                    }

                    & ~ .note-sm {
                        color: $color-pri !important;
                    }
                }
            }

            // &:last-child {
            //     @include hover {
            //         .box {
            //             &::before {
            //                 color: $color-second;
            //             }
            //         }
            //     }

            //     .box {
            //         background: linear-gradient(135deg, #3b2bf2 0%, #7820bd 100%);
            //         &::before {
            //             content: "+";
            //             position: absolute;
            //             top: 55%;
            //             left: 50%;
            //             transform: translate(-50%, -50%);
            //             background-image: none;
            //             color: $color-pri;
            //             opacity: 1;
            //             @include fz-24;
            //             display: flex;
            //             justify-content: center;
            //             align-items: center;
            //         }
            //     }
            // }

            input {
                display: none;
            }

            .box {
                display: block;
                width: 100%;
                height: 100%;
                position: relative;
                overflow: hidden;

                &::before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background-image: url(../assets/images/check.svg);
                    width: 2.8rem;
                    height: 2.8rem;
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                    transition: all 0.3s linear;
                    opacity: 0;
                    z-index: 2;

                    @media screen and (max-width: 800px) {
                        width: 2.2rem;
                        height: 2.2rem;
                    }

                    @media screen and (max-width: 360px) {
                        width: 1.8rem;
                        height: 1.8rem;
                    }
                }

                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba($color: #000000, $alpha: 0.5);
                    opacity: 0;
                    transition: all 0.3s linear;
                    z-index: 1;
                }
            }
        }
    }

    &_lf {
        width: calc(100% * 3 / 12);

        @media screen and (max-width: 1024px) {
            width: 0;
            padding-left: 0;
            padding-right: 0;
        }

        &--wrap {
            display: flex;
            flex-direction: column;
            row-gap: 2.4rem;
            position: sticky;
            top: 8rem;
            transition: all 1s ease-in-out;
            overflow: hidden;
            @media screen and (max-width: 1024px) {
                position: fixed;
                width: 50vw;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 4000;
                background-color: white;
                padding: 3.2rem 6rem;
                row-gap: 1.2rem;
                opacity: 0;
                visibility: hidden;
                pointer-events: none;
                transition: all 0.3s ease;

                &.actived {
                    opacity: 1;
                    visibility: visible;
                    pointer-events: visible;
                }
            }

            @media screen and (max-width: 800px) {
                width: 55vw;
                // padding: 2rem;
            }

            @media screen and (max-width: 600px) {
                width: 65vw;
                padding: 2rem;
            }

            @media screen and (max-width: 460px) {
                width: 75vw;
                padding: 2rem;
            }

            @media screen and (max-width: 360px) {
                width: 85vw;
                padding: 2rem;
            }
        }

        &--item {
            .note-md {
                margin-bottom: 1.2rem;
            }

            &-ex {
                position: absolute;
                top: 1rem;
                right: 1rem;
                width: 2rem;
                height: 2rem;
                cursor: pointer;
                i {
                    font-size: 2.4rem;
                }
            }

            &:not(:last-child) {
                padding-bottom: 2.4rem;
                border-bottom: 0.1rem solid $color-gray;

                @media screen and (max-width: 1024px) {
                    padding-bottom: 1.2rem;
                }
            }

            &:nth-child(4) {
                padding-bottom: 0;
                border: none;
            }

            &:last-child {
                display: none;
                border: none;
                @media screen and (max-width: 1024px) {
                    display: block;
                }

                .btn {
                    margin: auto;
                }
            }

            &:first-child {
                display: none;
                border: none;
                padding-bottom: 0rem;
                .note-lg {
                    text-align: center;
                    font-size: 2rem;
                    font-weight: 700;

                    @media screen and (max-width: 500px) {
                        font-size: 1.8rem;
                    }
                }
                @media screen and (max-width: 1024px) {
                    display: block;
                }
            }
        }
    }

    &_rt {
        flex: 1;
        width: 100%;

        &--list {
            row-gap: 2.4rem;

            @media screen and (max-width: 1024px) {
                row-gap: 2rem;
            }

            @media screen and (max-width: 600px) {
                row-gap: 0.8rem;
            }
        }

        &--item {
            @media screen and (max-width: 1024px) {
                width: calc(100% / 3);
            }

            @media screen and (max-width: 600px) {
                width: calc(100% / 2);
            }
        }

        &--wrap {
            width: 100%;
        }
    }

    &_item {
        display: flex;
        flex-direction: column;
        row-gap: 0.4rem;
        // transition: box-shadow 0.6s linear;
        // padding: 1.2rem;
        @include hover {
            // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            .product_item--img {
                &::before {
                    left: 150%;
                    transition: left 1.2s ease-in-out;
                    opacity: 0.1;
                }

                img {
                    transform: translate(-50%, -50%) scale(1.1);
                    opacity: 1;
                }
            }
        }

        @media screen and (max-width: 1200px) {
            // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            .product_item--img {
                &::before {
                    left: 150%;
                    transition: left 1.2s ease-in-out;
                    opacity: 0.1;
                }

                img {
                    transform: translate(-50%, -50%) scale(1.1);
                    opacity: 1;
                }
            }
        }

        @media screen and (max-width: 800px) {
            padding: 0.8rem;
        }

        &--top {
            position: relative;
        }

        &--alert {
            @include fz-12;
            text-transform: uppercase;
            font-family: var(--font-sec);
            padding: 0.4rem 1.6rem;
            background: $color-second;
            width: fit-content;
            position: absolute;
            color: $color-pri;
            top: 1rem;
            right: 1rem;
            z-index: 2;
            display: block;

            @media screen and (max-width: 1200px) {
                top: 0;
                right: 0;
            }

            @media screen and (max-width: 600px) {
                padding: 0.2rem 1.2rem;
            }

            @media screen and (max-width: 460px) {
                padding: 0.2rem 0.6rem;
            }
        }

        &--img {
            padding-top: calc(100% * 350 / 262);
            width: 100%;
            position: relative;
            overflow: hidden;
            display: block;
            &::before {
                content: "";
                position: absolute;
                top: -30%;
                left: -150%;
                width: 40%;
                height: 400%;
                transform: rotate(25deg);
                // background: rgba($color: #fff, $alpha: 0.2);
                z-index: 1;
                opacity: 0;
                background: linear-gradient(
                    to left,
                    rgba(255, 255, 255, 0.13) 0%,
                    rgba(255, 255, 255, 0.15) 60%,
                    rgba(255, 255, 255, 0.5) 90%,
                    rgba(255, 255, 255, 0) 100%
                );
            }

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                height: 100%;
                object-fit: cover;
                opacity: 0.95;
                transition: all 0.3s linear 0.3s;

                @media screen and (max-width: 1200px) {
                    opacity: 1;
                }
            }
        }

        &--top {
        }

        &--bottom {
            display: flex;
            flex-direction: column;
            row-gap: 0.1rem;

            &-top {
                .note-text {
                    color: $color-pri;

                    @media screen and (max-width: 800px) {
                        font-size: 1.2rem;
                    }
                }
            }

            &-bottom {
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-wrap: wrap;
                column-gap: 0.6rem;
                .note-text {
                    color: $color-pri;
                    font-weight: 700;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    @media screen and (max-width: 800px) {
                        font-size: 1.6rem;
                    }

                    @media screen and (max-width: 460px) {
                        font-size: 1.2rem;
                        font-weight: 600;
                    }
                }

                .title-mn {
                    transition: all 0.3s linear;

                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    @media screen and (max-width: 800px) {
                        font-size: 1.6rem;
                    }

                    @media screen and (max-width: 360px) {
                        font-size: 1.4rem;
                        font-weight: 600;
                    }
                }
            }
        }
    }
}

.modal-pro-filter {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.6);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    z-index: 3999;

    &.actived {
        opacity: 1;
        visibility: visible;
        pointer-events: visible;
    }
}

.product_ct {
    overflow: hidden;
    background: radial-gradient(249.59% 70.63% at 30.35% 50%, #222 0%, #000 100%);
    padding: var(--size-hd) 0 var(--size-hd-tl) 0;
    min-height: 100vh;
    display: flex;
    align-items: center;
    margin-bottom: var(--size-pd);

    @media screen and (max-width: 1200px) {
        padding: var(--size-hd-tl) 0 var(--size-hd-tl) 0;

        min-height: 100vh;
        // min-height: calc(100vh - var(--size-hd-tl));
        margin-bottom: var(--size-pd-tl);
    }

    // @media screen and (max-width: 800px) {
    //     min-height: unset;
    // }

    @media screen and (max-width: 600px) {
        // padding: 6rem 0 2rem 0;
        // min-height: unset;
        margin-bottom: var(--size-pd-mb);
    }

    @media screen and (max-width: 460px) {
        // padding: 8rem 0 2rem 0;
        // min-height: unset;
    }

    &--wrap {
        flex-wrap: nowrap;

        @media screen and (max-width: 800px) {
            flex-direction: column;
        }

        @media screen and (max-width: 600px) {
            row-gap: 1rem;
        }
    }

    &--lf {
        width: calc(100% * 8 / 12);

        .gItem {
            &::before {
                display: none;
            }
        }

        @media screen and (max-width: 1200px) {
            // width: 100%;
            width: calc(100% * 7.5 / 12);
        }

        @media screen and (max-width: 800px) {
            width: 100%;
        }

        &-wrap {
            width: 100%;
            position: relative;

            .btn-navi {
                top: 50%;
                transform: translateY(-50%);
                position: absolute;
                &.prev {
                    left: 0;

                    @media screen and (max-width: 1060px) {
                        left: -1.5rem;
                    }
                }

                &.next {
                    right: 0;

                    @media screen and (max-width: 1060px) {
                        right: -1.5rem;
                    }
                }
            }

            .product_ct--rt-bottom-thumbs {
                max-width: 60%;
                margin: auto;
                display: none;

                @media screen and (max-width: 600px) {
                    display: block;
                }
            }
        }

        &-box {
            max-width: 54.7rem;
            width: 100%;
            margin: auto;

            @media screen and (max-width: 600px) {
                max-width: 40rem;
            }

            @media screen and (max-width: 460px) {
                max-width: 30rem;
            }
        }

        &-list {
        }

        &-item {
        }

        &-img {
            .magnify {
                padding-top: calc(100% * 660 / 547);
                width: 100%;
                display: block;
                position: relative;
                overflow: hidden;

                &-lens {
                    width: 15rem;
                    height: 15rem;
                    border-radius: 0% !important;
                    box-shadow: unset !important;
                    border: 0.2rem solid $color-white !important;
                    background-color: $color-pri !important;
                }
            }

            @media screen and (max-width: 1200px) {
                padding-top: calc(100% * 660 / 547);
                width: 100%;
                display: block;
                position: relative;
                overflow: hidden;
            }

            img {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                object-fit: cover;
            }
        }
    }

    &--rt {
        flex: 1;
        width: 100%;

        .title-md {
            border-bottom: 0.1rem solid $color-white;
            width: fit-content;
            margin-bottom: 2rem;

            @media screen and (max-width: 1200px) {
                margin-bottom: 0.4rem;
                font-size: 4rem;
            }
        }

        &-wrap {
            width: 100%;
            display: flex;
            flex-direction: column;
            row-gap: 3.2rem;

            @media screen and (max-width: 1200px) {
                row-gap: 1.6rem;
            }

            @media screen and (max-width: 800px) {
                flex-direction: row;
                column-gap: 1.2rem;
                justify-content: space-between;
                align-items: start;
            }

            @media screen and (max-width: 600px) {
                flex-direction: column;
                row-gap: 1.2rem;
            }
        }

        &-top {
            display: flex;
            flex-direction: column;
            row-gap: 0.8rem;
            width: 100%;

            @media screen and (max-width: 800px) {
                // width: 50%;
                row-gap: 0.4rem;
                width: calc(100% * 6 / 12);
            }

            @media screen and (max-width: 600px) {
                width: 100%;
            }

            &-content {
                display: flex;
                flex-direction: column;
                row-gap: 2rem;

                .note-sm {
                }
            }

            &-des {
                display: flex;
                flex-direction: column;
                row-gap: 0.8rem;

                @media screen and (max-width: 800px) {
                    flex-direction: row;
                    column-gap: 1.2rem;
                    justify-content: space-between;
                }

                @media screen and (max-width: 700px) {
                    flex-direction: column;
                }

                @media screen and (max-width: 460px) {
                    flex-direction: row;
                }

                @media screen and (max-width: 300px) {
                    flex-direction: column;
                }
            }

            &-kilos {
                display: flex;
                justify-content: space-between;
                column-gap: 0.5rem;
                @media screen and (max-width: 800px) {
                    width: 80%;
                }
            }

            &-kilo {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                row-gap: 0.4rem;
                width: 50%;

                .note-text {
                    @media screen and (max-width: 800px) {
                        font-size: 1.4rem;
                    }

                    @media screen and (max-width: 460px) {
                        font-size: 1.2rem;
                    }
                }

                @media screen and (max-width: 460px) {
                    justify-content: unset;
                }
            }

            &-sizes {
                display: flex;
                flex-direction: column;
                row-gap: 0.4rem;
                width: fit-content;

                .note-text {
                    @media screen and (max-width: 800px) {
                        font-size: 1.4rem;
                    }
                }
            }
        }

        &-bottom {
            display: flex;
            flex-direction: column;
            row-gap: 3.2rem;

            @media screen and (max-width: 1200px) {
                row-gap: 1.6rem;
            }

            @media screen and (max-width: 800px) {
                width: calc(100% * 5 / 12);
                flex-direction: column-reverse;
                row-gap: 1.4rem;
            }

            @media screen and (max-width: 700px) {
                width: calc(100% * 5.5 / 12);
                flex-direction: column-reverse;
                row-gap: 1.4rem;
            }

            @media screen and (max-width: 600px) {
                width: 100%;
            }

            &-price {
                display: flex;
                flex-direction: column;
                row-gap: 0.4rem;
                width: 100%;

                .title-mn {
                    @media screen and (max-width: 1200px) {
                        font-size: 2.4rem;
                    }

                    @media screen and (max-width: 460px) {
                        font-size: 1.8rem;
                    }
                }
            }

            &-btn {
                display: flex;

                column-gap: 1.2rem;

                .btn {
                    height: 4rem;
                }
            }

            &-social {
                display: flex;
                flex-direction: column;
                row-gap: 1.2rem;
                .note-sm {
                    text-decoration: underline;
                }

                .social-mb-list {
                    padding: 0;
                    margin: 0;
                }

                .social-mb-link {
                    width: 3.2rem;
                    height: 3.2rem;
                    background: $color-white;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    @include hover {
                        background: $color-second;
                        transform: scale(1.2);
                    }

                    img {
                        width: 2.4rem;
                        height: 2.4rem;
                    }

                    @media screen and (max-width: 800px) {
                        width: 2.8rem;
                        height: 2.8rem;

                        img {
                            width: 2rem;
                            height: 2rem;
                        }
                    }
                }
            }

            &-thumbs {
                width: 100%;

                @media screen and (max-width: 800px) {
                    max-width: 90%;
                }

                @media screen and (max-width: 600px) {
                    display: none;
                }

                .swiper-slide {
                    width: calc(100% / 4);
                }

                .swiper-scrollbar {
                    padding: 0;
                }
            }
        }

        &-thumbs {
            .swiper-slide-thumb-active {
                .product_ct--rt-thumb {
                    opacity: 1;
                }
            }
        }

        &-thumb {
            transition: all 0.3s linear;
            opacity: 0.4;
        }

        &-img {
            padding-top: calc(100% * 106 / 71);
            position: relative;
            width: 100%;
            display: block;

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

.size {
    &-list {
        display: flex;
        column-gap: 0.8rem;
        align-items: center;
    }

    &-item {
        position: relative;
        cursor: pointer;
        input {
            display: none;

            &:checked {
                & ~ .box {
                    border-color: $color-second;
                }

                & ~ .note-mn {
                    color: $color-second;
                }
            }
        }

        .box {
            position: relative;
            width: 3.2rem;
            height: 3.2rem;
            flex-shrink: 0;
            border: 0.1rem solid $color-white;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all 0.3s linear;

            @media screen and (max-width: 1024px) {
                width: 2.4rem;
                height: 2.4rem;
            }
        }

        .note-mn {
            font-size: 1.2rem;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: $color-white;
            transition: all 0.3s linear;
        }
    }
}

.product_parameter {
    padding-top: var(--size-pd);
    padding-bottom: var(--size-pdt);

    @media screen and (max-width: 1200px) {
        padding-top: var(--size-pd-tl);
        padding-bottom: var(--size-pdl-tl);
    }

    @media screen and (max-width: 600px) {
        padding-top: var(--size-pd-mb);
        padding-bottom: var(--size-pdl-mb);
    }

    .title-sm {
        margin-bottom: 2rem;

        @media screen and (max-width: 1200px) {
            font-size: 2.4rem;
        }

        @media screen and (max-width: 600px) {
            margin-bottom: 1.2rem;
            font-size: 2rem;
        }
    }

    &--wrap {
    }

    &--list {
        row-gap: 2rem;

        @media screen and (max-width: 800px) {
            row-gap: 1.6rem;
        }

        @media screen and (max-width: 460px) {
            display: none;
        }

        &-sl {
            display: none;
            @media screen and (max-width: 460px) {
                display: flex;
            }

            .product_parameter--content {
                background: $color-white;
                padding: 1.2rem;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
                height: 100%;
            }

            .swiper-slide {
                width: 80%;
                height: auto;
            }
        }
    }

    &--item {
        @media screen and (max-width: 800px) {
            width: calc(100% / 2);
        }
    }

    &--content {
        display: flex;
        flex-direction: column;
        row-gap: 0.5rem;

        .note-text {
            @media screen and (max-width: 800px) {
                font-size: 1.4rem;
            }
        }
    }

    &--title {
        display: flex;
        align-items: center;
        column-gap: 0.8rem;

        .note-text {
            @media screen and (max-width: 800px) {
                font-size: 1.2rem;
            }
        }
    }

    &--box {
        width: 4.4rem;
        height: 4.4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-family: var(--font-sec);
        border: 0.1rem solid $color-text;
        border-radius: 0.4rem;
        font-weight: 600;

        @media screen and (max-width: 800px) {
            height: 4rem;
            width: 4rem;
            font-size: 1.2rem;
        }
    }
}

.product_cl {
    padding-top: var(--size-pdl);
    padding-bottom: var(--size-pdl);

    @media screen and (max-width: 1200px) {
        padding-top: var(--size-pdl-tl);
        padding-bottom: var(--size-pdl-tl);
    }

    @media screen and (max-width: 600px) {
        padding-top: var(--size-pdl-mb);
        padding-bottom: var(--size-pdl-mb);
    }

    .title-sm {
        margin-bottom: 1.2rem;

        @media screen and (max-width: 1200px) {
            font-size: 2.4rem;
        }

        @media screen and (max-width: 600px) {
            margin-bottom: 1.2rem;
            font-size: 2rem;
        }
    }

    &--wrap {
        align-items: start;

        @media screen and (max-width: 600px) {
            flex-direction: column-reverse;
            row-gap: 1.2rem;
        }
    }

    &--lf {
        @media screen and (max-width: 600px) {
            width: 100%;
        }

        &-list {
            row-gap: 0.8rem;
        }

        &-box {
            max-width: 7.1rem;
            width: 100%;
            margin: auto;

            @media screen and (max-width: 800px) {
                max-width: 5.2rem;
            }
        }

        &-img {
            padding-top: 100%;
            width: 100%;
            position: relative;
            display: block;
            overflow: hidden;

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 100%;
                height: 100%;
                transform: translate(-50%, -50%);
                object-fit: cover;
                transition: all 0.3s linear;
            }
        }

        &-item {
            @media screen and (max-width: 600px) {
                width: calc(100% / 3);
            }
            &.actived {
                .product_cl--lf-content {
                    border-color: $color-black;

                    @include hover {
                        .product_cl--lf-img {
                            img {
                                transform: translate(-50%, -50%) scale(1);
                            }
                        }
                    }
                }
            }
        }

        &-content {
            display: flex;
            flex-direction: column;
            row-gap: 0.5rem;
            cursor: pointer;
            border: 0.1rem solid transparent;
            transition: all 0.3s linear;
            border-radius: 0.4rem;
            user-select: none;
            @include hover {
                .product_cl--lf-img {
                    img {
                        transform: translate(-50%, -50%) scale(0.9);
                    }
                }
            }

            .note-sm {
                text-align: center;

                @media screen and (max-width: 800px) {
                    font-size: 1.2rem;
                }
            }
        }
    }

    &--rt {
        @media screen and (max-width: 600px) {
            width: 100%;
        }

        &-wrap {
            width: 100%;
            height: 100%;
        }

        &-list {
            width: 100%;
            height: 100%;
        }

        &-content {
            max-width: 54.8rem;
            width: 100%;
            margin: auto;

            @media screen and (max-width: 800px) {
                max-width: 33.8rem;
            }
        }

        &-item {
            display: none;

            &.showed {
                display: block;
                animation: anHienNon 0.5s linear forwards;
            }

            @keyframes anHienNon {
                0% {
                    opacity: 0.8;
                }

                100% {
                    opacity: 1;
                }
            }
        }

        &-img {
            padding-top: 100%;
            width: 100%;
            height: 100%;
            display: block;
            position: relative;
            overflow: hidden;

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 100%;
                height: 100%;
                transform: translate(-50%, -50%);
                object-fit: cover;
            }
        }
    }
}

.product_qc {
    overflow: hidden;
    &--list {
    }

    &--lf {
        width: calc(100% * 4 / 12);

        @media screen and (max-width: 700px) {
            width: calc(100% * 5 / 12);
        }

        @media screen and (max-width: 600px) {
            width: 100%;
        }

        &-wrap {
            width: 100%;
            display: flex;
            height: 100%;
            align-items: center;
        }

        &-content {
            padding: 5rem 0;
            // max-width: 35.7rem;
            width: 100%;
            @media screen and (max-width: 600px) {
                padding: 1.6rem 0;
            }

            @media screen and (max-width: 460px) {
                padding: 0.8rem 0;
            }
        }
    }

    &--rt {
        flex: 1;

        @media screen and (max-width: 600px) {
            width: 100%;
        }

        &-wrap {
            height: 100%;
            // min-height: 48.3rem;

            // @media screen and (max-width:1200px) {
            //     min-height: 30rem;
            // }
        }

        &-img {
            padding-top: calc(100% * 483 / 899);
            width: 100%;
            height: 100%;
            position: relative;
            display: block;
            height: 100%;
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 100%;
                height: 100%;
                transform: translate(-50%, -50%);
                object-fit: cover;
            }
        }
    }

    &--wrap {
        // align-items: center;

        @media screen and (max-width: 600px) {
            flex-direction: column !important;
        }
    }

    &--item {
        &:nth-child(odd) {
            .product_qc--lf-content {
                .title-sm {
                    text-align: start;

                    @media screen and (max-width: 1200px) {
                        text-align: end;
                        font-size: 2.4rem;
                    }

                    @media screen and (max-width: 600px) {
                        text-align: end;
                        font-size: 2rem;
                    }
                }

                .note-text {
                    text-align: start;

                    @media screen and (max-width: 1200px) {
                        text-align: end;
                    }

                    @media screen and (max-width: 800px) {
                        font-size: 1.4rem;
                    }

                    @media screen and (max-width: 460px) {
                        font-size: 1.2rem;
                    }
                }
            }

            .product_qc--wrap {
                flex-direction: row;

                @media screen and (max-width: 1200px) {
                    flex-direction: row-reverse;
                }
            }

            .product_qc--rt-wrap {
                margin-right: calc((100vw - 112rem) / -2);

                @media screen and (max-width: 1200px) {
                    margin-right: 0;
                    margin-left: -3rem;
                }

                @media screen and (max-width: 600px) {
                    margin-right: -3rem;
                    margin-left: -3rem;
                }
            }
        }

        &:nth-child(even) {
            .product_qc--wrap {
                flex-direction: row-reverse;

                @media screen and (max-width: 1200px) {
                    flex-direction: row;
                }
            }

            .product_qc--rt-wrap {
                margin-left: calc((100vw - 112rem) / -2);

                @media screen and (max-width: 1200px) {
                    margin-right: -3rem;
                    margin-left: 0;
                }

                @media screen and (max-width: 600px) {
                    margin-right: -3rem;
                    margin-left: -3rem;
                }
            }

            .product_qc--lf-content {
                .title-sm {
                    text-align: end;

                    @media screen and (max-width: 1200px) {
                        text-align: start;
                        font-size: 2.4rem;
                    }

                    @media screen and (max-width: 600px) {
                        text-align: start;
                        font-size: 2rem;
                    }
                }

                .note-text {
                    text-align: end;
                    @media screen and (max-width: 1200px) {
                        text-align: start;
                    }

                    @media screen and (max-width: 800px) {
                        font-size: 1.4rem;
                    }

                    @media screen and (max-width: 460px) {
                        font-size: 1.2rem;
                    }
                }
            }
        }
    }
}

.product_video {
    position: relative;
    height: calc(100vw * 810 / 1440);
    overflow: hidden;
    // clip-path: inset(0 0 0);

    @media screen and (max-width: 800px) {
        height: calc(100vw * 418 / 744);
    }

    @media screen and (max-width: 600px) {
        height: calc(100vw * 210 / 375);
    }

    &--bg {
        width: 100%;
        height: 100%;
        position: absolute;
        inset: 0;
        z-index: -1;

        @media screen and (max-width: 1200px) {
            width: 100%;
            height: 100%;
            position: absolute;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
        }
    }

    &--wrap {
        width: 100%;
        height: 100%;
        position: relative;

        .btn {
            position: absolute;
            bottom: 4rem;
            right: 0rem;

            @media screen and (max-width: 1200px) {
                bottom: 2.4rem;
            }

            @media screen and (max-width: 600px) {
                bottom: 1.2rem;
                right: -1.2rem;
            }
        }
    }
}

.product_like {
    padding: 8rem 0;
    overflow: hidden;

    @media screen and (max-width: 800px) {
        padding: 2.4rem 0;
    }

    &--wrap {
        align-items: center;
        flex-wrap: nowrap;

        @media screen and (max-width: 700px) {
            flex-direction: column;
            align-items: start;
            row-gap: 1rem;
        }
    }

    &--lf {
        width: calc(100% * 3 / 12);

        @media screen and (max-width: 920px) {
            width: calc(100% * 3.5 / 12);
            flex-shrink: 0;
        }

        @media screen and (max-width: 700px) {
            width: 100%;
        }

        &-wrap {
            max-width: 18rem;
            width: 100%;
            .title-sm {
                @media screen and (max-width: 1200px) {
                    font-size: 3.2rem;
                }

                @media screen and (max-width: 700px) {
                    font-size: 2rem;
                }
            }

            @media screen and (max-width: 700px) {
                max-width: none;
            }
        }
    }

    &--rt {
        flex: 1;
        @media screen and (max-width: 1200px) {
            width: 100%;
        }

        &-wrap {
            margin-right: calc((100vw - 112rem) / -2);

            @media screen and (max-width: 1200px) {
                margin-right: -3rem;
            }
        }

        &-list {
            width: 100%;
            .swiper {
                padding: 0.5rem 0;

                // @media screen and (max-width: 1200px) {
                //     padding: 0;
                // }
            }
            .swiper-slide {
                width: 28%;
                height: auto;

                // @media screen and (max-width:1200px) {
                //     width: 40%;
                // }

                @media screen and (max-width: 900px) {
                    width: 32%;
                }

                @media screen and (max-width: 600px) {
                    width: 45%;
                }

                @media screen and (max-width: 320px) {
                    width: 60%;
                }
            }

            .product_item {
                height: 100%;
            }
        }
    }
}
