.qt {
    &_wrap {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        clip-path: inset(0 0 0);

        .swiper-scrollbar {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 2;
            right: 4rem;
            width: 1.2rem;
            height: 50%;
            margin: 0;
            background: transparent;
            border: 0.1rem solid $color-gray;

            @media screen and (max-width: 1200px) {
                right: 0.6rem;
                width: 1rem;
                display: none;
            }
        }
    }

    &_img {
        &--list {
            position: fixed;
            inset: 0;
            width: 100vw;
            min-height: calc(100vh - var(--size-hd));

            @media screen and (max-width: 1200px) {
                width: 100%;
                height: 100%;
                min-height: calc(100vh - var(--size-hd));
            }

            .swiper {
                height: 100vh !important;
            }

            .swiper-slide {
                height: 100vh !important;
            }
        }

        &--item {
            height: 100%;
            width: 100%;
            user-select: none;
            cursor: n-resize;

            @media screen and (max-width: 1200px) {
                cursor: default;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    &_content {
        position: relative;
        width: 100%;
        min-height: calc(100vh - var(--size-hd));
        pointer-events: none;
        display: flex;
        align-items: center;

        &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0.1rem;
            height: 50%;
            background: $color-white;
            z-index: 2;
        }

        @media screen and (max-width: 1200px) {
            min-height: calc(100vh - var(--size-hd-tl));
        }
    }

    &_list {
        width: 100%;
        padding: 5rem 0 5rem;
        z-index: 2;
        display: flex;
        flex-direction: column;
        row-gap: 1.2rem;
        padding-left: 2rem;
        position: relative;
        &::before {
            content: "";
            position: absolute;
            bottom: -5rem;
            left: 0;
            width: 0.1rem;
            height: calc(100% - 4.8rem);
            background: $color-white;
            z-index: 2;

            @media screen and (max-width: 1200px) {
                height: calc(100% - 4rem);
            }
        }
    }

    &_item {
        display: flex;
        align-items: center;
        user-select: none;
        @keyframes anHienItem {
            0% {
                opacity: 0;
                transform: translateY(30%);
            }
            50% {
                opacity: 0.5;
                transform: translateY(20%);
            }
            100% {
                opacity: 1;
                transform: translateY(10%);
            }
        }

        &.actived {
            .qt_item--title {
                .title-mn {
                    font-size: 4rem;
                    transform: translateY(0.8rem);
                    @media screen and (max-width: 1200px) {
                        transform: translateY(0.8rem);
                        font-size: 3.2rem;
                    }

                    @media screen and (max-width: 600px) {
                        font-size: 2.4rem;
                        transform: translateY(1.2rem);
                    }
                }
                &::before {
                    bottom: 0rem;
                }
            }

            .qt_item--content {
                display: block;
                opacity: 0;
                transform: translateY(8%);
                animation: anHienItem 1s linear forwards;
            }
        }

        &--title {
            position: relative;
            height: 4.8rem;
            display: flex;
            align-items: center;

            @media screen and (max-width: 1200px) {
                height: 4rem;
            }

            .title-mn {
                transition: all 0.5s linear;
                transform: translateY(1.5rem);

                @media screen and (max-width: 1200px) {
                    font-size: 2rem;
                    transform: translateY(1.25rem);
                }

                @media screen and (max-width: 600px) {
                    font-size: 1.8rem;
                    transform: translateY(1.5rem);
                }
            }

            &:first-child {
                bottom: 0;
            }

            &::before {
                content: "";
                width: 1.6rem;
                height: 0.1rem;
                background: $color-white;
                bottom: 0rem;
                left: -2rem;
                position: absolute;
                transition: all 0.5s linear;
            }
        }

        &--content {
            display: none;
            max-width: 40rem;
            width: 100%;

            .note-text {
                @media screen and (max-width: 800px) {
                    font-size: 1.4rem;
                }

                @media screen and (max-width: 460px) {
                    font-size: 1.2rem;
                }
            }
        }

        &--wrap {
            display: flex;
            flex-direction: column;
            row-gap: 0.5rem;
        }
    }
}
