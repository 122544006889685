.menu {
    &-list {
        display: flex;

        margin-left: -1.6rem;
        margin-right: -1.6rem;
        height: 100%;

        // @media screen and (max-width: 1200px) {
        //     margin-left: -1.2rem;
        //     margin-right: -1.2rem;
        // }
    }

    &-ic {
        margin-top: -0rem;

        i {
            font-size: 1.2rem;
            font-weight: 600;
            line-height: 1.5;
        }
    }

    &-item {
        // padding: 0 1.5rem;
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        // overflow: hidden;
        &::before {
            content: "";
            position: absolute;
            top: -0.1%;
            left: 0%;
            width: 100%;
            height: 100%;
            border-radius: 0rem;
            background: $color-pri;
            transition: all 0.5s linear;
            opacity: 0;
            z-index: 1;
        }

        &.current-menu-item {
            &::before {
                opacity: 1;
            }
            & > .menu-link {
                color: $color-white;
            }
        }

        &.dropdown {
            @keyframes hienLen2 {
                0% {
                    transform: translateY(5%);
                    opacity: 0;
                }

                50% {
                    transform: translateY(2.5%);
                    opacity: 0.5;
                }

                100% {
                    transform: translateY(0%);
                    opacity: 1;
                }
            }

            @include hover {
                & > .menu-list {
                    opacity: 1;
                    visibility: visible;
                    transition: all 0.3s linear;
                    animation: hienLen2 0.3s linear;
                }

                & > .mega-menu {
                    transform: translateY(0%);
                    opacity: 1;
                    visibility: visible;
                    pointer-events: visible;
                }
            }
        }

        & > .menu-list {
            position: absolute;
            top: calc(100% + 0rem);
            opacity: 0;
            visibility: hidden;
            left: 15%;
            display: flex;
            flex-direction: column;
            min-width: 20rem;
            height: auto;
            background: $color-white;

            box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);

            z-index: 2;
            transition: all 0.5s ease;

            &::before {
                content: "";
                position: absolute;
                width: 100%;
                height: 2rem;
                background-color: transparent;
                top: -10%;
                left: 0;
            }

            .menu-item {
                padding: 0;

                &.current-menu-item {
                    .menu-link {
                        color: $color-second;
                        font-weight: 600;
                    }
                }
            }

            .menu-link {
                width: 100%;
                border-radius: 0;
                padding: 1rem 1.2rem;
                color: $color-pri;

                &::before {
                    display: none;
                }
            }
        }

        .mega-menu {
            position: fixed;
            padding: 2.4rem 0;
            width: 100%;
            top: var(--size-hd);
            left: 0;
            background: $color-white;
            transform: translateY(10%);
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
            transition: all 0.3s linear;
            border-top: 0.1rem solid #cccccc;
            &::before {
                content: "";
                position: absolute;
                top: -4rem;
                left: 0;
                width: 100%;
                height: 8rem;
                background: transparent;
            }

            &-wrap {
                width: 100%;
            }

            &-lf {
                width: calc(100% * 2.5 / 12);
                border-right: 0.1rem solid #cccccc;
                &-wrap {
                    max-height: 50vh;
                    overflow-y: auto;
                    overflow-x: hidden;
                    min-height: 24vh;
                }

                &-list {
                    display: flex;
                    flex-direction: column;
                    // row-gap: 1.6rem;
                }

                &-item {
                    &.actived {
                        .mega-menu-lf-link {
                            color: $color-second;

                            &::before {
                                filter: brightness(0) saturate(100%) invert(89%) sepia(17%) saturate(7049%)
                                    hue-rotate(344deg) brightness(101%) contrast(107%);
                                opacity: 1;
                            }
                        }
                    }
                }

                &-link {
                    position: relative;
                    width: 100%;
                    display: block;
                    padding: 0.8rem 0;
                    @include hover {
                        &::before {
                            filter: brightness(0) saturate(100%) invert(89%) sepia(17%) saturate(7049%)
                                hue-rotate(344deg) brightness(101%) contrast(107%);
                            opacity: 1;
                        }
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        top: 50%;
                        right: 0;
                        transform: translateY(-50%);
                        width: 1.4rem;
                        height: 1.4rem;
                        opacity: 0;
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                        background-image: url(../assets/images/arr.png);
                    }
                }
            }

            &-rt {
                width: 100%;
                flex: 1;

                &-wrap {
                }

                &-list {
                    padding-left: 1.4rem;
                }

                &-item {
                    @keyframes truotLenMega {
                        0% {
                            transform: translateY(10%);
                            opacity: 0;
                        }

                        100% {
                            transform: translateY(0%);
                            opacity: 1;
                        }
                    }

                    display: none;
                    &.actived {
                        display: block;
                        animation: truotLenMega 1s ease;
                    }
                }

                &-parent {
                    width: 100%;
                    height: 100%;

                    max-height: 50vh;
                    overflow-y: auto;
                    overflow-x: hidden;
                }

                &-child {
                }

                &-link {
                    display: flex;
                    flex-direction: column;
                    row-gap: 0.4rem;

                    .note-text {
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }

                    @include hover {
                        .mega-menu-rt-img {
                            &::before {
                                left: 150%;
                                transition: left 1.2s ease-in-out;
                                opacity: 0.15;
                            }

                            img {
                                transform: translate(-50%, -50%) scale(1.1);
                                opacity: 1;
                            }
                        }
                    }
                }

                &-img {
                    padding-top: 100%;
                    width: 100%;
                    position: relative;
                    overflow: hidden;

                    &::before {
                        content: "";
                        position: absolute;
                        top: -30%;
                        left: -150%;
                        width: 40%;
                        height: 400%;
                        transform: rotate(25deg);
                        // background: rgba($color: #fff, $alpha: 0.2);
                        z-index: 1;
                        opacity: 0;
                        background: linear-gradient(
                            to left,
                            rgba(255, 255, 255, 0.13) 0%,
                            rgba(255, 255, 255, 0.15) 60%,
                            rgba(255, 255, 255, 0.5) 90%,
                            rgba(255, 255, 255, 0) 100%
                        );
                    }

                    img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        opacity: 0.95;
                        transition: all 0.3s linear 0.3s;

                        @media screen and (max-width: 1200px) {
                            opacity: 1;
                        }
                    }
                }
            }
        }

        @include hover {
            &::before {
                opacity: 1;
            }

            & > .menu-link {
                color: $color-white;
            }
        }
    }

    &-link {
        padding: 0rem 1.6rem;
        color: $color-pri;
        font-family: var(--font-sec);
        font-weight: 600;
        column-gap: 0.6rem;
        display: flex;
        align-items: center;
        white-space: nowrap;
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 2;
        text-transform: uppercase;

        // @media screen and (max-width: 1400px) {
        //     padding: 0rem 0.8rem;
        // }
    }
}

.menu-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.5);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    z-index: 1999;

    &.actived {
        opacity: 1;
        visibility: visible;
    }
}

// .modal-filter {
//     position: fixed;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background: rgba($color: #000000, $alpha: 0.6);
//     opacity: 0;
//     visibility: hidden;
//     transition: all 0.3s ease;
//     z-index: 1000;

//     &.showed {
//         opacity: 1;
//         visibility: visible;
//     }
// }

.menu-mb {
    position: fixed;
    // background: linear-gradient(0deg, #142f5e 0%, #14213f 39.53%, #141829 76.36%, #141421 100%);
    background: white;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);

    width: 35%;
    height: calc(100% - #{var(--size-hd-tl)});
    top: var(--size-hd-tl);
    left: -150%;
    z-index: 2000;
    padding: 0rem 0rem 1rem 0rem;
    transition: all 0.5s ease-in-out;

    .btn {
        width: fit-content;
        margin: 0 auto 2rem;
    }

    .menu-list {
        display: flex;
        flex-direction: column;
        height: 80vh;
        overflow-y: auto;
        overflow-x: hidden;
        // width: 100%;
        margin: 0;
    }

    .list-address {
        row-gap: 1rem;
        padding: 0 0.5rem;
        display: flex;
        flex-direction: column;
    }

    .ic-address {
        border: none !important;
        border-radius: unset !important;
        width: 2.4rem;
        // height: 1.8rem;
        height: 100%;
        flex-shrink: 0;
        // margin-top: -0.3rem;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 100%;
            height: 100%;
            filter: brightness(0) saturate(100%) invert(0%) sepia(6%) saturate(1643%) hue-rotate(324deg) brightness(88%)
                contrast(81%);
        }
    }

    .item-address {
        align-items: center !important;
        column-gap: 1rem;
        flex-direction: row;
        display: flex;
    }

    .link-address {
        color: $color-text;
        font-weight: 600;
        font-size: 1.4rem;
    }

    .menu-item {
        width: 100%;
        height: unset;
        flex-direction: column;
        align-items: unset;
        // padding-right: 2.4rem;
        padding: 0;
        border-bottom: 0.1rem solid $color-gray2;

        &::before {
            display: none;
        }

        &:last-child {
            .menu-link {
                color: $color-pri !important;
                font-weight: 700;
                background: $color-second !important;
                &::before {
                    display: none;
                }

                .menu-ic i {
                    color: $color-second;
                }
            }
        }

        &.current-menu-item {
            & > .menu-link {
                color: $color-second;
                font-weight: 700;
                background: $color-pri;
                &::before {
                    display: none;
                }

                .menu-ic i {
                    color: $color-second;
                }
            }
        }

        &-box {
            display: flex;
            column-gap: 1rem;
            align-items: center;
            justify-content: space-between;
            padding-right: 1.2rem;
        }

        &.dropdown {
            & > .menu-list {
                position: relative;
                transform: none;
                top: 0;
                left: 0;
                opacity: 1;
                visibility: visible;
                min-width: unset;
                background-color: transparent;
                box-shadow: none;
                margin-left: 2.8rem;
                margin-top: -1rem;
                display: none;
                height: auto;
                padding-bottom: 0.4rem;
                transition: none;
                height: 100%;
                &::before {
                    content: "";
                    position: absolute;
                    top: 0.5rem !important;
                    left: 0;
                    height: auto;
                    bottom: 0.6rem !important;
                    width: 0.1rem;
                    background: $color-gray2;
                }

                &.showed {
                    display: block;
                }

                & > .menu-item {
                    border-bottom: none;

                    &:last-child {
                        .menu-link {
                            background:none !important;
                        }
                    }

                    &.current-menu-item {
                        .menu-link {
                            color: $color-second;
                            background: $color-pri;
                        }
                    }

                    .menu-list > .menu-item {
                        .menu-list > .menu-item > .menu-link {
                            font-size: 1.3rem;
                        }
                    }

                    & > .menu-link {
                        width: 100%;
                        color: $color-text;
                        padding: 0.4rem 1rem 0.4rem 1rem;
                        font-size: 1.4rem;
                        font-weight: 400;
                        text-transform: none;
                        justify-content: space-between;
                    }
                }
            }
        }

        .menu-link {
            width: 100%;
            padding: 0.8rem 2.4rem;
            justify-content: space-between;
            height: auto;
            font-weight: 600;
            font-size: 1.6rem;
            white-space: unset;
        }
    }

    .menu-ic {
        flex-shrink: 0;
        width: 2.4rem;
        height: 100%;
        position: relative;
        z-index: 5;
        display: flex;
        align-items: center;
        justify-content: center;
        i {
            font-size: 1.8rem;
            color: $color-black;
            transition: all 0.3s linear;
        }

        // margin-top: -0.3rem;
        cursor: pointer;
        transition: all 0.3s linear;

        &.actived {
            transform: rotate(-180deg);
        }
    }

    &.actived {
        left: 0;
    }

    @media screen and (max-width: 1024px) {
        width: 50%;
    }

    @media screen and (max-width: 800px) {
        width: 60%;
    }

    @media screen and (max-width: 600px) {
        width: 70%;
    }

    @media screen and (max-width: 500px) {
        width: 80%;
    }

    @media screen and (max-width: 460px) {
        width: 100%;
    }

    @media screen and (max-width: 360px) {
        width: 100%;
    }

    &-wrap {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        width: 100%;
    }
}

.social-mb {
    &-list {
        display: flex;
        align-items: center;
        // justify-content: space-between;
        margin-top: 1rem;
        margin-bottom: 1rem;

        column-gap: 1.2rem;
        padding: 0 0.6rem;
    }

    &-item {
        // width: 100%;
        display: flex;
        width: fit-content;
        justify-content: center;

        // &:not(:last-child) {
        //     border-right: 0.1rem solid $color-text;
        // }
    }

    &-link {
        width: 2.4rem;
        height: 2.4rem;
        flex-shrink: 0;
        display: block;
        border-radius: 50%;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
        }
    }
}

.menu-lh {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    .note-title {
        padding: 0 0.6rem;
        font-weight: 700;
    }
    &-title {
        padding: 0 0.6rem;
        @include fz-18;
        font-weight: 700;
        color: $color-pri;
        line-height: 1.5;
        // margin-bottom: 1rem;
        text-transform: uppercase;

        @media screen and (max-width: 600px) {
            font-size: 1.6rem;
        }
    }

    margin-bottom: 1rem;
    padding: 0 0.6rem;
}

.menu-mxh {
    padding: 2rem 0.6rem 1rem;
    margin-bottom: 2rem;
    border-bottom: 0.1rem solid rgba($color: #000000, $alpha: 0.2);

    border-top: 0.1rem solid rgba($color: #000000, $alpha: 0.2);
}

.mega-menu-rt-link {
    display: flex;
    flex-direction: column;
    row-gap: 0.4rem;

    .note-text {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    @include hover {
        .mega-menu-rt-img {
            &::before {
                left: 150%;
                transition: left 1.2s ease-in-out;
                opacity: 0.15;
            }

            img {
                transform: translate(-50%, -50%) scale(1.1);
                opacity: 1;
            }
        }
    }
}

.mega-menu-rt-img {
    padding-top: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;

    &::before {
        content: "";
        position: absolute;
        top: -30%;
        left: -150%;
        width: 40%;
        height: 400%;
        transform: rotate(20deg);
        // background: rgba($color: #fff, $alpha: 0.2);
        z-index: 1;
        opacity: 0;
        background: linear-gradient(
            to left,
            rgba(255, 255, 255, 0.13) 0%,
            rgba(255, 255, 255, 0.15) 60%,
            rgba(255, 255, 255, 0.5) 90%,
            rgba(255, 255, 255, 0) 100%
        );
    }

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0.95;
        transition: all 0.3s linear 0.3s;

        @media screen and (max-width: 1200px) {
            opacity: 1;
        }
    }
}

.result-search {
    position: fixed;
    top: var(--size-hd);
    width: 100%;
    background: $color-white;
    transform: translateY(10%);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all 0.3s linear;
    @media screen and (max-width: 1200px) {
        display: none;
    }
    &.actived {
        transform: translateY(0%);
        opacity: 1;
        visibility: visible;
        pointer-events: visible;
        transition: all 0.3s linear;
    }

    &-wrap {
        width: 100%;
        padding: 2.4rem 0;
        max-height: 50vh;
        overflow-y: auto;
        overflow-x: hidden;
    }

    &-list {
        width: 100%;
        row-gap: 2.4rem;
    }

    &-item {
        width: calc(100% / 6);
    }
}

.result-search-mb {
    position: fixed;
    top: 0;
    width: 50%;
    left: -150%;
    background: $color-white;
    z-index: 4000;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all 0.5s ease-in-out;

    display: none;

    @media screen and (max-width: 1200px) {
        display: block;
    }

    @media screen and (max-width: 768px) {
        width: 60%;
    }

    @media screen and (max-width: 600px) {
        width: 70%;
    }

    @media screen and (max-width: 460px) {
        width: 100%;
    }

    &.actived {
        left: 0;
        opacity: 1;
        visibility: visible;
        pointer-events: visible;

        & ~ .result-mobi-modal {
            opacity: 1;
            visibility: visible;
        }
    }

    .header-search-ex {
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: center;

        i {
            @include fz-24;
        }
    }

    &-box {
        display: flex;
        // align-items: center;
        justify-content: space-between;
        flex-direction: row-reverse;
        border-bottom: 0.1rem solid $color-gray2;
        .header-search-ip {
            border: none;
            width: 90%;
        }

        .header-search-ip input {
            padding: 2rem 1.2rem;
        }
    }

    &-wrap {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 1.2rem;
    }

    &-list {
        // width: 100%;
        row-gap: 1.2rem;
    }

    &-view {
        padding: 0 1.2rem;
        height: calc(100% - 8rem);
        overflow-y: auto;
    }

    &-item {
        width: calc(100% / 6);
    }
}

.result-mobi-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.6);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    z-index: 3999;
}
