.home_nb {
    padding-top: var(--size-pd);
    padding-bottom: var(--size-pd);

    @media screen and (max-width: 1200px) {
        padding-top: var(--size-pd-tl);
        padding-bottom: var(--size-pd-tl);
    }

    @media screen and (max-width: 600px) {
        padding-top: var(--size-pd-mb);
        padding-bottom: var(--size-pd-mb);
    }

    &.item-ani {
        &.showed {
            .title-sm3 {
                clip-path: inset(0 0 0 0);
            }
        }
    }

    .title-sm3 {
        margin-bottom: 2.4rem;
        clip-path: inset(0 100% 0 0);
        transition: all 3s linear;
        @media screen and (max-width: 1200px) {
            margin-bottom: 1.6rem;
            clip-path: inset(0 0 0 0);
        }

        @media screen and (max-width: 800px) {
            font-size: 3.2rem;
        }

        @media screen and (max-width: 600px) {
            margin-bottom: 1.2rem;
            font-size: 2.4rem;
        }

        @media screen and (max-width: 360px) {
            font-size: 2rem;
        }
    }

    &--list {
        .swiper {
            padding: 0.5rem 0;
        }

        .swiper-slide{
            height: auto;
        }

        .product_item{
            height: 100%;
        }
    }
}

.home_tm {
    position: relative;
    // clip-path: inset(0 0 0);
    width: 100%;
    height: 100%;

    margin-top: var(--size-pd);

    @media screen and (max-width: 1200px) {
        margin-top: var(--size-pd-tl);
    }

    @media screen and (max-width: 600px) {
        margin-top: var(--size-pd-mb);
    }

    overflow: hidden;
    &--bg {
        width: 100%;
        height: 100%;
        position: absolute;
        inset: 0;
        z-index: -1;

        @media screen and (max-width: 1200px) {
            position: absolute;
            width: 100%;
            height: 100%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &--wrap {
        position: relative;
        z-index: 2;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        min-height: 81rem;

        @media screen and (max-width: 800px) {
            min-height: 99rem;
            align-items: end;
        }

        @media screen and (max-width: 600px) {
            min-height: 60rem;
            align-items: end;
        }

        @media screen and (max-width: 360px) {
            min-height: 50rem;
            align-items: end;
        }
    }

    &--content {
        padding: 10rem 0;
        display: flex;
        flex-direction: column;
        row-gap: 1.6rem;
        max-width: 35.7rem;
        width: 100%;

        @media screen and (max-width: 1200px) {
            max-width: 30rem;
        }

        @media screen and (max-width: 800px) {
            padding: 10rem 0 2.4rem;
            row-gap: 1.2rem;
        }

        @media screen and (max-width: 600px) {
            max-width: none;
        }

        @media screen and (max-width: 460px) {
            row-gap: 0.8rem;
        }

        .title-sm3 {
            max-width: 26rem;
            width: 100%;

            @media screen and (max-width: 800px) {
                font-size: 3.2rem;
            }

            @media screen and (max-width: 600px) {
                max-width: none;
                font-size: 2.4rem;
            }

            @media screen and (max-width: 460px) {
                
            }

            @media screen and (max-width: 360px) {
                font-size: 2rem;
            }
        }

        .note-text {
            @media screen and (max-width: 800px) {
                font-size: 1.4rem;
            }

            @media screen and (max-width: 460px) {
                font-size: 1.2rem;
            }
        }
    }
}

.home_dm {
    padding-top: var(--size-pdl);
    padding-bottom: var(--size-pdl);
    background: $color-pri;

    &.item-ani {
        &.showed {
            .title-sm3 {
                clip-path: inset(0 0 0 0);
            }
        }
    }

    @media screen and (max-width: 1200px) {
        padding-top: var(--size-pdl-tl);
        padding-bottom: var(--size-pdl-tl);
    }

    @media screen and (max-width: 600px) {
        padding-top: var(--size-pdl-mb);
        padding-bottom: var(--size-pdl-mb);
    }

    .title-sm3 {
        margin-bottom: 2.4rem;
        color: $color-second;
        clip-path: inset(0 100% 0 0);
        transition: all 3s linear;
        @media screen and (max-width: 1200px) {
            margin-bottom: 1.6rem;
            clip-path: inset(0 0 0 0);
        }

        @media screen and (max-width: 800px) {
            font-size: 3.2rem;
        }

        @media screen and (max-width: 600px) {
            margin-bottom: 1.2rem;
            font-size: 2.4rem;

        }

        @media screen and (max-width: 360px) {
            font-size: 2rem;
        }
    }

    &--list {
        .post_item--link {

            .note-text{
                color: $color-white;

            }

            @include hover{
                .note-text{
                    color: $color-second;
    
                }
            }
        }

        .swiper {
        }

        .swiper-slide {
            @media screen and (max-width: 360px) {
                width: calc(100% / 2);
            }
        }
    }
}

.home_anc {
    overflow: hidden;
    padding-bottom: 2rem;

    @media screen and (max-width: 800px) {
        padding-bottom: 0;
    }

    &--list {
        display: flex;
        row-gap: 2rem;
        justify-content: space-between;

        @media screen and (max-width: 800px) {
            row-gap: 0;
            flex-direction: column;
        }
    }

    &--bg {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        z-index: -1;

        &::before {
            content: "";
            inset: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            background: rgba($color: #000000, $alpha: 0.5);
            opacity: 0;
            transition: all 0.5s linear;

            @media screen and (max-width: 1200px) {
                opacity: 1;
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &--link {
        width: 100%;
        height: 100%;
        position: relative;
        min-height: 81rem;
        display: flex;
        align-items: end;
        justify-content: center;

        @media screen and (max-width: 800px) {
            min-height: 99rem;
        }

        @media screen and (max-width: 600px) {
            min-height: 60rem;
        }

        @include hover {
            .home_anc--bg {
                &::before {
                    opacity: 1;
                }
            }
        }
    }

    &--item {
        position: relative;

        height: 100%;

        &:nth-child(3n + 1) {
            width: 100%;
        }

        &:nth-child(3n + 2) {
            width: 50%;
        }

        &:nth-child(3n + 3) {
            width: 50%;
        }

        @media screen and (max-width: 800px) {
            width: 100% !important;
        }
    }

    &--wrap {
        padding: 10rem 0 4.8rem;
        max-width: 90%;
        display: flex;
        flex-direction: column;
        row-gap: 1.6rem;
        align-items: center;

        @media screen and (max-width: 800px) {
            row-gap: 1.2rem;
            padding: 10rem 0 2.4rem;
        }

        @media screen and (max-width: 460px) {
            row-gap: 1.2rem;
            padding: 10rem 0 2.4rem;
            max-width: 70%;
        }

        .title-sm3 {
            text-align: center;

            @media screen and (max-width: 800px) {
                font-size: 3.2rem;
            }

            @media screen and (max-width: 600px) {
                font-size: 2.4rem;
            }

            @media screen and (max-width: 360px) {
                font-size: 2rem;
            }
        }
    }
}
