.footer {
  padding-top: 4rem;
  padding-bottom: 4rem;
  // min-height: 64rem;
  position: relative;

  overflow: hidden;

  @media screen and (max-width: 1200px) {
    padding-top: 5.2rem;
    padding-bottom: 5.2rem;
  }

  @media screen and (max-width: 460px) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  // &::before {
  //   content: "";
  //   width: 100%;
  //   height: 100%;
  //   background-image: url(../assets/images/bgFooter.png);
  //   inset: 0;
  //   background-repeat: no-repeat;
  //   background-size: 100% 100%;
  //   position: absolute;
  //   z-index: -1;
  // }

  &-bg {
    position: absolute;
    inset: 0;
    width: 101%;
    height: 100%;
    z-index: -1;
  }

  &-img {
    padding-top: calc(100% * 644 / 1440);
    width: 100%;
    position: relative;
    overflow: hidden;
    height: 100%;

    @media screen and (min-width: 1440px) {
      padding-top: calc(100% * 644 / 1728);
    }

    @media screen and (max-width: 1200px) {
      padding-top: calc(100% * 498 / 1200);
    }

    @media screen and (max-width: 800px) {
      padding-top: calc(100% * 498 / 800);
    }

    @media screen and (max-width: 744px) {
      padding-top: calc(100% * 498 / 744);
    }

    @media screen and (max-width: 600px) {
      padding-top: calc(100% * 486 / 600);
    }

    @media screen and (max-width: 460px) {
      padding-top: calc(100% * 486 / 460);
    }

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      object-fit: cover;
      // object-position: top;

      @media screen and (max-width: 800px) {
        object-position: 65%;
      }

      @media screen and (max-width: 700px) {
        object-position: 70%;
      }

      @media screen and (max-width: 600px) {
        object-position: center;
      }
    }
  }

  &-wrap {
    position: relative;
    z-index: 2;
  }

  &-top {
    padding-bottom: 2.4rem;
    border-bottom: 0.1rem solid $color-gray;

    @media screen and (max-width: 1200px) {
      padding-bottom: 2rem;
    }

    @media screen and (max-width: 500px) {
      padding-bottom: 1.6rem;
    }

    .title-sm2 {
      max-width: 54rem;
      width: 100%;
      margin-bottom: 0.4rem;
    }

    &-wrap {
      display: flex;
      align-items: end;
      justify-content: space-between;
      column-gap: 2rem;
      .note-sm {
        max-width: 54rem;
        width: 100%;
      }
    }

    .footer-social {
      @media screen and (max-width: 1200px) {
        display: none;
      }
    }
  }

  &-center {
    padding-top: 2.4rem;
    padding-bottom: 6rem;

    @media screen and (max-width: 1200px) {
      padding-bottom: 4rem;
    }

    @media screen and (max-width: 500px) {
      padding-bottom: 3rem;
    }

    .footer-social {
      display: none;
      @media screen and (max-width: 1200px) {
        display: flex;
        // width: 100%;
        // flex: 1;
      }

      .note-md {
        @media screen and (max-width: 1200px) {
          display: none !important;
        }
      }
    }

    &-wrap {
      display: flex;
      flex-direction: column;
      row-gap: 2.4rem;

      width: 100%;
      @media screen and (max-width: 1200px) {
        width: 100%;
        row-gap: 2rem;
      }

      @media screen and (max-width: 500px) {
        width: 100%;
        row-gap: 1.6rem;
      }
    }
    &-top {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: start;
    }

    &-bottom {
      display: flex;
      flex-direction: column;
      row-gap: 1.2rem;

      &-title {
        display: flex;
        flex-direction: column;
        row-gap: 0.4rem;
      }

      &-ip {
        display: flex;
        align-items: center;
        width: 100%;
        flex: 1;

        .border {
          display: block;
          pointer-events: none;
          &::before {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            border: 0.1rem solid $color-second;
            inset: -0.1rem;
            background: transparent;
            pointer-events: none;
            transition: all 0.5s linear;
            opacity: 0;
          }

          &::after {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            border: 0.1rem solid $color-white;
            inset: -0.1rem;
            background: transparent;
            pointer-events: none;
            transition: all 0.5s linear;
            opacity: 1;
          }
        }

        .form-item-ip {
          // width: 100%;
          // height: 100%;

          @media screen and (max-width: 1200px) {
            padding: 0 0.6rem;
          }
          &::placeholder {
            color: $color-gray;
          }

          &:focus ~ .border {
            &::before {
              opacity: 1 !important;
            }

            &::after {
              opacity: 0 !important;
            }
          }
        }
      }

      &-form {
        // border: 0.1rem solid $color-white;
        max-width: 36rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 0.6rem;

        &-wrap {
          display: flex;
          justify-content: space-between;
          position: relative;
        }

        @media screen and (max-width: 800px) {
          row-gap: 0.4rem;
        }

        @media screen and (max-width: 460px) {
          row-gap: 0.2rem;
          max-width: none;
        }

        .btn {
          @media screen and (max-width: 460px) {
            padding: 0 1.2rem;
            min-width: 8rem;
          }
        }
      }

      &-check {
        display: flex;
        align-items: start;
        column-gap: 0.6rem;
        // padding-left: 0.2rem;
        @media screen and (max-width: 460px) {
          column-gap: 0.2rem;
        }

        input {
          display: none;

          &:checked {
            & ~ .box {
              border-color: $color-second;

              &::before {
                opacity: 1;
              }
            }
          }
        }

        .box {
          width: 2rem;
          height: 2rem;
          border: 0.2rem solid $color-white;
          border-radius: 0.2rem;
          cursor: pointer;
          position: relative;
          transition: all 0.3s linear;
          flex-shrink: 0;
          @media screen and (max-width: 600px) {
            width: 1.6rem;
            height: 1.6rem;
            transform: translateY(0.2rem);
          }

          &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-image: url(../assets/images/check.svg);
            width: 1.8rem;
            height: 1.8rem;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            transition: all 0.3s linear;
            opacity: 0;

            @media screen and (max-width: 500px) {
              width: 1.4rem;
              height: 1.4rem;
            }
          }
        }

        .note-sm {
          color: $color-white;
          font-family: var(--font-sec);
          // white-space: nowrap;
          @include fz-14;

          @media screen and (max-width: 460px) {
            @include fz-12;
          }
          a {
            font-weight: 700;
            text-decoration: underline;
          }
        }
      }
    }

    &-list {
      // &.d-wrap{
      //   margin-left: -2.2rem;
      //   margin-right: -2.2rem;
      // }

      // .d-item{
      //   padding-left: 2.2rem;
      //   padding-right: 2.2rem;
      // }
      width: calc(100% * 6.5 / 12);
      row-gap: 1.2rem;

      @media screen and (max-width: 1200px) {
        width: calc(100% * 6 / 12);
        row-gap: 0.4rem;
      }
    }

    &-item {
      @media screen and (max-width: 1200px) {
        width: calc(100% / 2) !important;
      }

      @media screen and (max-width: 460px) {
        width: 100% !important;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      row-gap: 0.8rem;

      .note-md {
        @media screen and (max-width: 500px) {
          font-weight: 600;
        }
      }

      .menu-list {
        display: flex;
        flex-direction: column;
        margin-left: 0;
        margin-right: 0;

        @media screen and (max-width: 1200px) {
          flex-direction: row;
          flex-wrap: wrap;
          column-gap: 0.8rem;
          display: none;
        }
      }

      .menu-item {
        &::before {
          display: none;
        }
      }
      .menu-link {
        color: $color-white;
        font-weight: 400;
        padding: 0.5rem 0;
        @include fz-14;
        font-family: var(--font-sec);
        // width: fit-content;
        text-align: start;
        align-items: start;
        text-transform: capitalize;
        @include hover {
          color: $color-second !important;
        }
      }
    }
  }

  &-bottom {
    padding-top: 2.4rem;
    border-top: 0.1rem solid $color-gray;

    @media screen and (max-width: 1200px) {
      padding-top: 2rem;
    }

    @media screen and (max-width: 500px) {
      padding-top: 0.8rem;
    }

    &-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 0.5rem;

      @media screen and (max-width: 370px) {
        flex-direction: column;
        row-gap: 0.5rem;
      }

      .note-sm {
        @media screen and (max-width: 500px) {
          @include fz-12;
        }
      }
    }

    &-lang {
      display: flex;
      align-items: end;
      column-gap: 1.2rem;

      @media screen and (max-width: 500px) {
        column-gap: 0.6rem;
        display: none;
      }

      .link {
        position: relative;
        padding-left: 2.8rem;
        transition: none;
        @include hover {
          &::before {
            filter: brightness(0) saturate(100%) invert(81%) sepia(63%) saturate(3123%) hue-rotate(351deg)
              brightness(109%) contrast(99%);
          }
        }

        &::before {
          position: absolute;
          content: "";
          width: 2.4rem;
          height: 2.4rem;
          background-image: url(../assets/images/inter.svg);
          background-repeat: no-repeat;
          background-size: 100% 100%;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
        }
      }
    }

    &-lang2 {
      display: flex;
      align-items: end;
      column-gap: 0.3rem;
      display: none;
      @media screen and (max-width: 500px) {
        display: flex;
      }

      .note-sm {
        @include fz-12;
      }

      .link {
        position: relative;
        padding-left: 2rem;
        transition: none;

        @include hover {
          &::before {
            filter: brightness(0) saturate(100%) invert(81%) sepia(63%) saturate(3123%) hue-rotate(351deg)
              brightness(109%) contrast(99%);
          }
        }

        &::before {
          position: absolute;
          content: "";
          width: 1.6rem;
          height: 1.6rem;
          background-image: url(../assets/images/inter.svg);
          background-repeat: no-repeat;
          background-size: 100% 100%;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
        }
      }
    }
  }

  &-social {
    display: flex;
    align-items: center;
    column-gap: 1.2rem;

    .note-md {
      white-space: nowrap;
    }

    &-list {
      display: flex;
      align-items: center;
      column-gap: 1rem;

      @media screen and (max-width: 500px) {
        column-gap: 0.6rem;
      }
    }

    &-item {
    }

    &-link {
      width: 2rem;
      height: 2rem;
      display: block;
      @include hover {
        img {
          transform: scale(1.2);
          filter: unset;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        // transition: all 0.5s linear;
        filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(264deg) brightness(114%)
          contrast(105%);
      }
    }
  }
}
