.section {
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
    &.active {
        &.about_banner {
            &::before {
                animation: anHienAb 1s linear forwards 0.3s;
            }

            .about_banner--logo {
                animation: anHienAb 1s linear 1.5s forwards;
            }
        }

        &.about_us {
            &::before {
                opacity: 1;
            }
            .about_us--content {
                .title-sm3 {
                    color: $color-second;
                }

                .note-text {
                    color: $color-second;
                }
            }
        }

        &.about_action {
            .about_action--rt {
                .about_action--rt-img {
                    transform: translateX(6%);
                }
            }
        }

        &.about_said {
            .about_said--item {
                &:first-child {
                    transform: translateX(0%);
                    opacity: 1;
                    transition: all 1.5s ease;
                }

                &:nth-child(2) {
                    transform: translateY(0%);
                    opacity: 1;
                    transition: all 1.5s ease;
                }

                &:last-child {
                    transform: translateY(0%);
                    opacity: 1;
                    transition: all 1.5s ease;
                }
            }
        }

        &.about_see {
            .about_see--list {
                opacity: 1;
            }
        }
    }

    &:last-child {
        background: #000000;
        justify-content: unset;

        .fp-overflow {
            height: 100%;
        }

        .footer-bg {
            z-index: 1;
        }

        .footer {
            height: 100%;

            &-wrap {
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 100%;
            }
        }
    }

    &.about_said {
        .fp-overflow {
            height: 100%;
        }
    }

    .footer-img img {
        @media screen and (max-width: 1300px) {
            object-position: 75%;
        }

        @media screen and (max-width: 800px) {
            object-position: 50%;
        }

        @media screen and (max-width: 600px) {
            object-position: 60%;
        }
    }
}

.about_banner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    // min-height: 100vh;
    overflow: hidden;
    clip-path: inset(0 0 0);
    position: relative;

    @keyframes anHienAb {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    &::before {
        content: "";
        inset: 0;
        width: 100%;
        height: 100%;
        background: rgba($color: #000000, $alpha: 0.8);
        position: absolute;
        z-index: 1;
        opacity: 0;

        // animation: anHienAb 1s linear forwards 0.3s;
    }

    &--bg {
        position: fixed;
        width: 100vw;
        height: 100vh;
        inset: 0;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &--logo {
        max-width: 50rem;
        width: 100%;
        position: relative;
        z-index: 2;
        opacity: 0;
        margin-top: var(--size-hd);
        // animation: anHienAb 1s linear 1.5s forwards;

        @media screen and (max-width: 1200px) {
            margin-top: var(--size-hd-tl);
        }

        @media screen and (max-width: 800px) {
            max-width: 38rem;
        }

        @media screen and (max-width: 460px) {
            max-width: 18rem;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.about_us {
    width: 100%;
    // height: 100vh;
    position: relative;
    background: transparent;
    // transition: all 0.5s linear 0.5s;

    &::before {
        content: "";
        inset: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        position: absolute;
        background: radial-gradient(50% 50% at 50% 50%, #181818 0%, #000 100%);
        transition: all 1s linear 0.5s;
        z-index: -1;
    }

    // &.item-ani{
    //     &.showed{
    //         background: $color-pri;

    //         .about_us--content{
    //             .title-sm3{
    //                 color: $color-second;
    //             }

    //             .note-text{
    //                 color: $color-second;

    //             }
    //         }
    //     }
    // }

    &--wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 2;
    }

    &--content {
        display: flex;
        flex-direction: column;
        row-gap: 0.4rem;
        max-width: 58.4rem;
        width: 100%;

        @media screen and (max-width: 800px) {
            max-width: 45.7rem;
        }

        .title-sm3 {
            text-align: center;
            transition: all 0.5s linear 0.5s;
        }

        .note-text {
            transition: all 0.5s linear 0.5s;

            @media screen and (max-width: 800px) {
                font-size: 1.4rem;
            }

            @media screen and (max-width: 460px) {
                font-size: 1.2rem;
            }
        }
    }
}

.about_action {
    width: 100%;
    // height: 100vh;
    position: relative;
    overflow: hidden;
    // padding: 8.3rem 0;

    // &.item-ani {
    //     &.showed {
    //         .about_action--rt {
    //             .about_action--rt-img {
    //                 transform: translateX(0);
    //             }
    //         }
    //     }
    // }

    &--wrap {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    &--lf {
        width: calc(100% * 6 / 12);
        &-wrap {
            width: 100%;
            display: flex;
            flex-direction: column;
            row-gap: 0.4rem;

            .title-sm3 {
                @media screen and (max-width: 800px) {
                    max-width: 22rem;
                }

                @media screen and (max-width: 600px) {
                    max-width: 16rem;
                }

                @media screen and (max-width: 360px) {
                    font-size: 2rem;
                }
            }

            .note-text {
                color: $color-pri;

                @media screen and (max-width: 800px) {
                    font-size: 1.4rem;
                }

                @media screen and (max-width: 460px) {
                    font-size: 1.2rem;
                }
            }
        }
    }

    &--rt {
        flex: 1;
        &-wrap {
            margin-right: calc((100vw - 116rem) / -2);

            @media screen and (max-width: 1200px) {
                margin-right: -4.8rem;
            }
        }

        &-img {
            width: 100%;
            position: relative;
            overflow: hidden;
            display: block;
            // max-height: 73rem;
            transform: translateX(200%);
            transition: all 1s linear;
            padding-top: calc(103%);
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

.about_see {
    // padding-top: 4rem;
    // padding-bottom: 4rem;
    // height: 100vh;
    overflow: hidden;
    width: 100%;

    &--wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    &--list {
        max-width: 54.8rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 3.2rem;
        transition: all 1.2s linear;
        max-height: 80vh;
        overflow-y: auto;
        opacity: 0;

        @media screen and (max-width: 800px) {
            row-gap: 2.4rem;
        }

        @media screen and (max-width: 360px) {
            row-gap: 2rem;
        }
    }

    &--item {
        // padding: 3.2rem 0;
        display: flex;
        flex-direction: column;
        row-gap: 0.4rem;

        .title-sm3 {
            @media screen and (max-width: 600px) {
                font-size: 2.4rem;
            }

            @media screen and (max-width: 360px) {
                font-size: 2rem;
            }
        }

        .note-text {
            @media screen and (max-width: 800px) {
                font-size: 1.4rem;
            }

            @media screen and (max-width: 460px) {
                font-size: 1.2rem;
            }
        }
    }
}

.about_said {
    width: 100%;
    // height: 100vh;
    background: radial-gradient(50% 50% at 50% 50%, #181818 0%, #000 100%);
    overflow: hidden;

    // &.item-ani {
    //     &.showed {
    //         .about_said--item {
    //             &:first-child {
    //                 transform: translateX(0%);
    //                 opacity: 1;
    //             }

    //             &:nth-child(2) {
    //                 transform: translateY(0%);
    //                 opacity: 1;
    //             }

    //             &:last-child {
    //                 transform: translateY(0%);
    //                 opacity: 1;
    //             }
    //         }
    //     }
    // }

    &--wrap {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    &--list {
        display: flex;
        flex-wrap: wrap;
        column-gap: 1rem;
        row-gap: 0.1rem;
        max-width: 50rem;
        // min-height: 20vh;
        width: 100%;
        // height: 100%;
        justify-content: center;
        align-items: end;
        position: relative;
        // @media screen and (max-width: 800px) {
        //     max-width: 70vw;
        // }

        @media screen and (max-width: 600px) {
            max-width: 30rem;
        }

        // @media screen and (max-width: 460px) {
        //     max-width: 40rem;
        // }
    }

    &--item {
        cursor: pointer;
        &:first-child {
            transform: translateX(-10000%);
            opacity: 0;
            .about_said--text {
                font-size: 2.4rem;
                font-weight: 700;
                text-transform: uppercase;
                color: $color-white;

                @media screen and (max-width: 600px) {
                    font-size: 2rem;
                }

                @media screen and (max-width: 360px) {
                    font-size: 1.4rem;
                }
            }

            &.active1 {
                opacity: 1 !important;
                transform: translateY(25%) !important;
                transition: transform 1s linear !important;

                @media screen and (max-width: 600px) {
                    transform: translate(-26%, -5%) !important;
                    transition: transform 1s ease !important;
                }

                @media screen and (max-width: 460px) {
                    transform: translate(-23%, -5%) !important;
                    transition: transform 1s ease !important;
                }

                @media screen and (max-width: 360px) {
                    transform: translate(18%, -65%) !important;
                    transition: transform 1s ease !important;
                }
            }

            &.active2 {
                opacity: 0 !important;
                transform: translate(0%, 10%) scale(0.3) !important;
                transition:
                    opacity 0.5s ease 0.25s,
                    transform 1s ease !important;
                pointer-events: none;
                .about_said--text {
                }
            }

            &.active3 {
                opacity: 0 !important;
                transition: opacity 0.5s ease;

                .about_said--text {
                }
            }
        }

        &:nth-child(2) {
            transform: translateY(-10000%);
            opacity: 0;
            .about_said--text {
                // line-height: 1;
                transform: translateY(1rem);
                font-size: 4.8rem;
                font-weight: 700;
                text-transform: uppercase;
                color: $color-second;

                @media screen and (max-width: 600px) {
                    font-size: 4rem;
                }

                @media screen and (max-width: 360px) {
                    font-size: 3rem;
                    transform: translateY(0.5rem);
                }
            }

            &.active1 {
                transform: translateX(-55%) !important;
                opacity: 0 !important;
                transition:
                    transform 0.5s ease,
                    opacity 0.5s ease 0.25s !important;
                pointer-events: none;
            }

            &.active2 {
                transform: translate(-103%, 45%) !important;
                transition: 1s ease !important;

                .about_said--text {
                    // font-size: 2.4rem;
                    transform: scale(0.5);
                    color: $color-white;
                    transition: 0.25s ease !important;
                }

                @media screen and (max-width: 600px) {
                    transform: translate(-123%, 30%) !important;
                }

                @media screen and (max-width: 460px) {
                    transform: translate(-100%, 25%) !important;
                }

                @media screen and (max-width: 380px) {
                    transform: translate(-96%, 25%) !important;
                }

                @media screen and (max-width: 370px) {
                    transform: translate(-94%, 25%) !important;
                }

                
                @media screen and (max-width: 360px) {
                    transform: translate(-71%, -10%) !important;
                }
            }

            &.active3 {
                transform: translateX(-55%) !important;
                opacity: 0 !important;
                transition:
                    transform 0.5s ease,
                    opacity 0.5s ease 0.25s;
                pointer-events: none;
            }
        }

        &:last-child {
            width: 100%;
            transform: translateY(10000%);
            opacity: 0;

            &.active1 {
                transform: translateY(-80%) !important;
                opacity: 0 !important;
                transition:
                    transform 0.5s ease,
                    opacity 0.5s ease 0.25s !important;
                pointer-events: none;
            }

            &.active2 {
                transform: translateY(-80%) !important;
                opacity: 0 !important;
                transition:
                    transform 0.5s ease,
                    opacity 0.5s ease 0.25s !important;
                pointer-events: none;
            }

            &.active3 {
                // width: fit-content !important;
                transform: translate(-16%, -55%) !important;
                // opacity: 0;
                transition: transform 0.5s ease !important;

                @media screen and (max-width: 600px) {
                    transform: translate(-31%, -70%) !important;
                }

                @media screen and (max-width: 460px) {
                    transform: translate(-29%, -75%) !important;
                }

                @media screen and (max-width: 360px) {
                    transform: translate(-15%, -105%) !important;

                    transition: transform 1s ease !important;
                }

                

              

                .about_said--text {
                    color: $color-white;
                    transform: scale(0.5);
                    transition: 0.5s ease !important;
                }
            }

            .about_said--text {
                font-size: 4.8rem;
                font-weight: 700;
                text-transform: uppercase;
                color: $color-gray;
                text-align: center;

                @media screen and (max-width: 600px) {
                    font-size: 4rem;
                }

                @media screen and (max-width: 360px) {
                    font-size: 3rem;
                }
            }
        }
    }

    &--text {
        white-space: nowrap;
        transition: all 0.3s linear;
    }

    &--contents {
        margin-top: -6rem;
        margin-left: -1rem;

        @media screen and (max-width: 460px) {
            // margin-left: 0;
            margin: -6rem auto 0;
        }
    }

    @keyframes anHienText {
        0% {
            opacity: 0;
        }
        50% {
            opacity: 0.5;
        }
        100% {
            opacity: 1;
        }
    }

    &--content {
        display: none;
        opacity: 0;
        max-width: 32rem;

        @media screen and (max-width: 460px) {
            max-width: none !important;
        }

        @media screen and (max-width: 360px) {
            max-width: 18rem !important;
        }

        &:nth-child(2) {
            max-width: 42rem;

            @media screen and (max-width: 460px) {
                max-width: 34rem !important;
            }

            @media screen and (max-width: 360px) {
                max-width: 18rem !important;
            }
        }

        &.actived {
            animation: anHienText 0.5s linear 0.5s forwards;
            display: block;
        }

        .note-text {
            // text-align: center;

            @media screen and (max-width: 360px) {
                font-size: 1.2rem;
            }
        }
    }
}
