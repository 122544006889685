.post {
    padding-top: var(--size-pd);
    padding-bottom: var(--size-pdl);

    @media screen and (max-width: 1200px) {
        padding-top: var(--size-pd-tl);
        padding-bottom: var(--size-pdl-tl);
    }

    @media screen and (max-width: 600px) {
        padding-top: var(--size-pd-mb);
        padding-bottom: var(--size-pdl-mb);
    }

    &_list {
        row-gap: 2.4rem;

        @media screen and (max-width: 1200px) {
            row-gap: 2rem;
        }

        @media screen and (max-width: 600px) {
            row-gap: 1rem;
        }
    }

    &_item {
        &--img {
            padding-top: calc(100% * 732 / 548);
            width: 100%;
            position: relative;
            overflow: hidden;

            &::before {
                content: "";
                position: absolute;
                top: -30%;
                left: -150%;
                width: 30%;
                height: 300%;
                transform: rotate(20deg);
                // background: rgba($color: #fff, $alpha: 0.2);
                z-index: 1;
                opacity: 0;
                background: linear-gradient(
                    to left,
                    rgba(255, 255, 255, 0.13) 0%,
                    rgba(255, 255, 255, 0.15) 60%,
                    rgba(255, 255, 255, 0.5) 90%,
                    rgba(255, 255, 255, 0) 100%
                );
            }

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                height: 100%;
                object-fit: cover;
                opacity: 0.95;
                transition: all 0.3s linear 0.3s;

                @media screen and (max-width: 1200px) {
                    opacity: 1;
                }
            }
        }

        &--link {
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 1.2rem;

            @media screen and (max-width: 1200px) {
                row-gap: 0.8rem;
            }

            @media screen and (max-width: 600px) {
                row-gap: 0.4rem;
            }

            @include hover {
                .post_item--img {
                    &::before {
                        left: 150%;
                        transition: left 1.2s ease-in-out;
                        opacity: 0.15;
                    }

                    img {
                        transform: translate(-50%, -50%) scale(1.1);
                        opacity: 1;
                    }
                }
            }

            .note-text {
                text-align: center;
                transition: all 0.3s linear;
                @media screen and (max-width: 460px) {
                    @include fz-12;
                }
            }
        }
    }
}

.post_info {
    padding-top: var(--size-pd);
    padding-bottom: var(--size-pd);

    @media screen and (max-width: 1200px) {
        padding-top: var(--size-pd-tl);
        padding-bottom: var(--size-pd-tl);
    }

    @media screen and (max-width: 600px) {
        padding-top: var(--size-pd-mb);
        padding-bottom: var(--size-pd-mb);
    }

    .title-sm3 {
        margin-bottom: 2rem;

        @media screen and (max-width: 1200px) {
            margin-bottom: 1.2rem;
        }
    }

    &--grid {
        &-list {
            gap: 2.4rem;
            display: grid;
            grid-template-areas:
                "h1 h1 h1 h2 h2 h2"
                "h1 h1 h1 h3 h3 h3";

            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
            grid-template-rows: minmax(23rem, 1fr);

            @media screen and (max-width: 1200px) {
                column-gap: 2rem;
                row-gap: 1.4rem;
            }

            @media screen and (max-width: 600px) {
                grid-template-areas:
                    "h1 h1 h1 h1 h1 h1"
                    "h1 h1 h1 h1 h1 h1"
                    "h2 h2 h2 h2 h2 h2"
                    "h3 h3 h3 h3 h3 h3";
            }

            grid-template-rows: minmax(30rem, 1fr);
        }

        &-item {
            &:first-child {
                grid-area: h1;
            }

            &:nth-child(2) {
                grid-area: h2;
            }

            &:nth-child(3) {
                grid-area: h3;
            }
        }

        &-img {
            width: 100%;
            height: 100%;
            display: block;
            overflow: hidden;
            position: relative;
            // padding-top: 100%;

            &::before {
                content: "";
                position: absolute;
                top: -30%;
                left: -150%;
                width: 40%;
                height: 400%;
                transform: rotate(25deg);
                // background: rgba($color: #fff, $alpha: 0.2);
                z-index: 1;
                opacity: 0;
                background: linear-gradient(
                    to left,
                    rgba(255, 255, 255, 0.13) 0%,
                    rgba(255, 255, 255, 0.15) 60%,
                    rgba(255, 255, 255, 0.5) 90%,
                    rgba(255, 255, 255, 0) 100%
                );
            }

            img {
                // position: absolute;
                // top: 50%;
                // left: 50%;
                // transform: translate(-50%,-50%);
                width: 100%;
                height: 100%;
                object-fit: cover;
                opacity: 0.95;
                transition: all 0.3s linear 0.3s;
                @media screen and (max-width: 1200px) {
                    opacity: 1;
                }
            }
        }

        &-content {
            display: flex;
            flex-direction: column;
            row-gap: 0.8rem;
            width: 100%;
            height: 100%;
            background: $color-white;
            padding-bottom: 2.8rem;

            @media screen and (max-width: 460px) {
                padding-bottom: 3.2rem;
            }

            @include hover {
                .post_info--grid-img {
                    &::before {
                        left: 150%;
                        transition: left 1.2s ease-in-out;
                        opacity: 0.1;
                    }

                    img {
                        transform: scale(1.1);
                        opacity: 1;
                    }
                }
            }
        }

        &-title {
            padding: 0 0.8rem;
            .note-text {
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                @media screen and (max-width: 800px) {
                    font-size: 1.4rem;
                }

                @media screen and (max-width: 460px) {
                    font-size: 1.2rem;
                }
            }
        }
    }
}

.post_old {
    padding-top: var(--size-pd);
    padding-bottom: var(--size-pdl);

    @media screen and (max-width: 1200px) {
        padding-top: var(--size-pd-tl);
        padding-bottom: var(--size-pdl-tl);
    }

    @media screen and (max-width: 600px) {
        padding-top: var(--size-pd-mb);
        padding-bottom: var(--size-pdl-mb);
    }

    .title-mn {
        margin-bottom: 2rem;

        @media screen and (max-width: 1200px) {
            margin-bottom: 1.2rem;
        }

        @media screen and (max-width: 460px) {
            font-size: 1.8rem;
        }
    }

    &--list {
        row-gap: 2.4rem;
        margin-bottom: 4rem;

        @media screen and (max-width: 800px) {
            row-gap: 2rem;
            margin-bottom: 2.4rem;
        }

        @media screen and (max-width: 460px) {
            row-gap: 0.8rem;
            margin-bottom: 1.6rem;
        }
    }

    &--item {
        width: calc(100% / 3);

        @media screen and (max-width: 600px) {
            width: calc(100% / 2);
        }

        .post_info--grid-content {
            @include hover {
                .post_info--grid-img {
                    img {
                        transform: translate(-50%, -50%) scale(1.1);
                        opacity: 1;
                    }
                }
            }
        }

        .post_info--grid-img {
            padding-top: 100%;
            position: relative;

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    .btn {
        margin: auto;
    }
}

.anc {
    position: relative;
    min-height: 90rem;
    width: 100%;
    clip-path: inset(0 0 0);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    @media screen and (max-width:800px) {
        min-height:85rem;
    }

    @media screen and (max-width:600px) {
        min-height:71.9rem;
    }

    &_bg {
        position: fixed;
        width: 100vw;
        height: 100vh;

        inset: 0;
        
        z-index: -1;

        &::before {
            content: "";
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            background: rgba($color: #000000, $alpha: 0.8);
            z-index: 1;
        }

        img {
            width: 100%;
            height: 100%;
            object-position: 50% 0;
            object-fit: cover;
        }
    }

    @keyframes truotLenNd {
        0%{
            transform:translateY(1000%);
            opacity: 0;
        }

        50%{
            transform:translateY(500%);
            opacity: 0;
        }

        100%{
            transform:translateY(0%);
            opacity: 1;
        }
    }

    &_wrap {
        display: flex;
        flex-direction: column;
        row-gap: 2rem;
        padding: 2.4rem 0;
        @media screen and (max-width: 1200px) {
            row-gap:1.2rem;
        }
    }

    &_title {
        transform:translateY(1000%);
        opacity: 0;
        animation: truotLenNd 1s ease forwards;
       
        .title-sm3 {
            text-align: center;

            @media screen and (max-width:460px) {
                max-width: 30rem;
                margin: auto;
            }
        }
    }

    &_content {
        display: flex;
        flex-direction: column;

        .note-text{
            @media screen and (max-width:800px) {
                font-size: 1.4rem;
            }

            @media screen and (max-width:460px) {
                font-size: 1.2rem;
            }
        }
    }

    &_list {
        display: flex;
        flex-direction: column;
        row-gap: 2.4rem;
        transform:translateY(1000%);
        opacity: 0;
        animation: truotLenNd 1.6s ease 0s forwards;

        @media screen and (max-width: 1200px) {
            row-gap: 2rem;
        }
    }

    &_item {
        display: flex;
        flex-direction: column;
        row-gap: 0.4rem;
    }
}
