.box-range-slider {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 0.8rem;
}
.wr-box-range-slider {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
}
.wr-box-range-slider input[type="range"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    outline: none;
    position: absolute;
    margin: auto;
    background-color: transparent;
    pointer-events: none;
}
.wr-box-range-slider .wr-slider-track {
    width: 100%;
    height: 0.3rem;
    position: relative;
    background-color: $color-gray2;
    overflow: hidden;
    border-radius: 0.5rem;
}
.wr-box-range-slider .slider-track {
    height: 100%;
    background-color: $color-second;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 0.5rem;
}
input[type="range"]::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    height: 0.5rem;
}
input[type="range"]::-moz-range-track {
    -moz-appearance: none;
    height: 0.5rem;
}
input[type="range"]::-ms-track {
    appearance: none;
    height: 0.5rem;
}
input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 1.6rem;
    height: 1.6rem;
    background-color: $color-second;
    cursor: pointer;
    pointer-events: auto;
    border-radius: 50%;
    margin-top: -0.5rem;
    border: 0.3rem solid $color-second;
}
.input[type="range"]::-moz-range-thumb {
    -webkit-appearance: none;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    pointer-events: auto;
    border-radius: 50%;
    background-color: $color-second;
}
.input[type="range"]::-ms-thumb {
    appearance: none;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    pointer-events: auto;
    border-radius: 50%;
    background-color: $color-second;
}
.input[type="range"]:active::-webkit-slider-thumb {
    background-color: $color-second;
    border: 0.1rem solid $color-second;
}
.wr-range-val {
    // margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
}
.wr-range-val span {
    color: $color-pri;
    font-size: 1.2rem;
    font-weight: 600;
    font-family: var(--font-sec);
}