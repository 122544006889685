@import url("../assets/font/segoe/stylesheet.css");
@import url("../assets/font/arial/stylesheet.css");





* {
    box-sizing: border-box;
}

html {
    font-size: 10px;
    scroll-behavior: smooth;

    @media screen and (min-width: 2000px) {
        font-size: 100%;
    }
}

body {
    font-family: 'Arial';
    font-size: 16px;
    line-height: 1.5;
    font-weight: 400;
    -webkit-tap-highlight-color: transparent;
    color: $color-text;
    background-color:#F2F2F2;

    &.about-bd{
        overflow: hidden !important;
    }

    @media screen and (min-width: 2000px) {
        font-size: 100%;
    }

    // @media screen and (max-width: 460px) {
    //     font-size: 14px;
    //   }
      &.actived {
        overflow: hidden;
      }
}


.lg-backdrop{
    z-index: 5000;
}
.lg-outer{
    z-index: 5000;
}

a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
    @include transition(0.3s);

    @include min(1200px) {
        &:hover {
            color: $color-second;
        }
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
    background-color: #F2F2F2 !important;
    color: $color-text !important;
    -webkit-box-shadow: 0 0 0 1000px #F2F2F2 inset !important;
    -webkit-text-fill-color: $color-text !important;
}




button,
select {
    border: none;
    outline: none;
    cursor: pointer;
    -webkit-appearance: none;
    cursor: pointer;
}

button {
    padding: 0;
    background-color: transparent;
    cursor: pointer;
}

input,
textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

button,
select,
input,
textarea {
    border: none;
    outline: none;
    box-shadow: none;
    font-family: inherit;
    color: $color-text;
    background: transparent;

    @media screen and (max-width:500px) {
        @include fz-12;
    }

    &:focus {
        border: none;
        outline: none;
        box-shadow: none;
    }

    &:focus-visible {
        border: none;
        outline: none;
        box-shadow: none;
    }
}

::-webkit-scrollbar { 
    width: 0.3rem;
  }
  
  ::-webkit-scrollbar-thumb {
    background: $color-second;
  }
  
  ::-webkit-scrollbar-track {
    background: $color-pri  !important;
    border-radius: 0.4rem !important;
  }

@supports (-webkit-touch-action: none){
    input,select,textarea {
        font-size: max(16px);
    }
}

.main {
    // min-height: 100vh;

    &.pt{
        padding-top: var(--size-hd);

        @media screen and (max-width:1200px) {
            padding-top: var(--size-hd-tl);
        }
    }

   
}

.container {
    width: 100%;
    max-width: 115rem;
    height: 100%;
    margin: 0 auto;
    padding: 0 1.5rem;

    @include max(1200px) {
        padding: 0 2.4rem;
    }

    // @include max(600px) {
    //     padding: 0 0.6rem;
    // }
}

.no-scroll {
    touch-action: none;
    -webkit-overflow-scrolling: none;
    overflow: hidden;
    overscroll-behavior: none;
}

iframe {
    vertical-align: middle;
}

img {
    max-width: 100%;
    height: auto;
}

.t-center {
    text-align: center;
}

.fw-b {
    font-weight: bold;
}

.fsi {
    font-style: italic;
}

.t-up {
    text-transform: uppercase;
}

.tabBtn {
    cursor: pointer;
}

.gItem {
    cursor: pointer;
}

.global-title {
    max-width: 0;
    max-height: 0;
    opacity: 0;
    visibility: hidden;
} 


.title{
    &-lg{
        @include fz-96;
        line-height: 1.4;

        @media screen and (max-width:800px){
            line-height:1.5;
        }
    }

    &-md{
        @include fz-64;
        line-height: 1.4;

        @media screen and (max-width:800px){
            line-height:1.5;
        }

        strong{
            color: $color-pri
        }
    }

    &-sm{
        @include fz-32;
    }

    &-sm2{
        @include fz-36;
    }

    &-sm3{
        @include fz-40;
        line-height: 1.3;

        @media screen and (max-width:800px){
            line-height:1.5;
        }

    }

    &-sm4{
        @include fz-48;
    }

    &-mn{
        @include fz-24;
    }

    &-mn2{
        @include fz-28;
    }

}

.note{
    &-title{
        font-weight: 700;
    }

    &-text{
        @include fz-16;
        strong{
            font-weight: 700;
            
        }

        @media screen and (max-width:460px) {
            
        }
    }

    &-lg{
        @include fz-20;
        font-weight: 600;

        strong{
            color: $color-pri
        }

    }

    &-md{
        @include fz-18;
    }

    &-sm{
        @include fz-14;
    }

    &-smn{
        @include fz-12;
    }

    &-ic{
        width: 1.2rem;
        height:auto;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease;
        i{  
            color: $color-pri;
            font-size: 1.4rem;
        }
    }
}


.fl{
    &-Arial{
        font-family: var(--font-pri);
    }

    &-Segoe{
        font-family: var(--font-sec);
    }

}

.cl{
    &-pri{
        color:$color-pri;
    }

    &-white{
        color:$color-white;

    }

    &-yellow{
        color: #F5D461;
    }

    &-second{
      color: $color-second;
    }

    &-text{
        color: $color-text;
    }

    &-text2{
        color: $color-text2;
    }

    &-text3{
        color: $color-text3;
    }

    &-gray{
        color: $color-gray;
    }

    &-gray2{
        color: $color-gray2;
    }

    &-gray3{
        color: $color-gray3;
    }

    &-gray4{
        color: $color-gray4;
    }


    &-black2{
        color: $color-black-2;
    }

    &-black3{
        color: $color-black-3;
    }

    &-black{
        color: $color-black;
    }

    
}

.link-text{
    color: $color-second;
    @include fz-20;
   font-weight: 600;

    @include hover{
        color: $color-pri;
    }
}



