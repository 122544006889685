.cs {
    padding-top: var(--size-pd);
    padding-bottom: var(--size-pdl);

    @media screen and (max-width: 1200px) {
        padding-top: var(--size-pd-tl);
        padding-bottom: var(--size-pdl-tl);
    }

    @media screen and (max-width: 600px) {
        padding-top: var(--size-pd-mb);
        padding-bottom: var(--size-pdl-mb);
    }

    .title-sm3 {
        margin-bottom: 0.8rem;

        @media screen and (max-width: 1200px) {
            margin-bottom: 0.6rem;
        }

        @media screen and (max-width: 600px) {
            margin-bottom: 0.4rem;
        }
    }

    &_img {
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &_wrap {
        display: flex;
        flex-direction: column;
        row-gap: 4rem;


        @media screen and (max-width:800px) {
            row-gap: 1.2rem;
        }

        @media screen and (max-width:600px) {
            row-gap: 2rem;
        }
    }

    &_content {
        display: flex;
        flex-direction: column;
        row-gap: 0.4rem;

        .note-md {
            font-size: 1.8rem;
            @media screen and (max-width: 460px) {
                font-size: 1.6rem;
                font-weight: 600;
            }
        }

        .note-text{
            @media screen and (max-width: 800px) {
                font-size: 1.4rem;
            }

            @media screen and (max-width: 460px) {
                font-size: 1.2rem;
            }
        }

        ul {
            display: flex;
            flex-direction: column;
            row-gap: 0.2rem;
            list-style: disc;
            margin-left: 2rem;
            li {
                font-family: var(--font-sec);
                @media screen and (max-width: 800px) {
                    font-size: 1.4rem;
                }

                @media screen and (max-width: 460px) {
                    font-size: 1.2rem;
                }
            }
        }

        &--wrap {
            margin-top: 1rem;

            @media screen and (max-width:600px) {
                flex-direction: column;
                row-gap: 1.2rem;
            }
        }

        &--lf {

            @media screen and (max-width: 800px) {
                width: calc(100% * 6.5 / 12);
            }

            @media screen and (max-width: 600px) {
                width: 100%;
            }
            &-content {
                width: 100%;
                display: flex;
                flex-direction: column;
                row-gap: 0.4rem;
            }
        }

        &--rt {
            @media screen and (max-width: 800px) {
                width: 100%;
                flex: 1;
            }
            &-content {
                height: 100%;
                width: 100%;
            }

            &-img {
                width: 100%;
                height: 100%;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}

.dk {
    position: relative;
    overflow: hidden;
    clip-path: inset(0 0 0);
    display: flex;
    justify-content: center;
    align-items: center;
    &_bg {
        width: 100vw;
        height: 100vh;
        position: fixed;
        inset: 0;
        z-index: -1;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            inset: 0;
            background: rgba(255, 255, 255, 0.12);
            backdrop-filter: blur(2rem);
        }
    }

    &_wrap {
        padding: 34rem 2.4rem;
        display: flex;
        flex-direction: column;
        row-gap: 2rem;
        align-items: center;

        @media screen and (max-width:800px) {
        padding: 15.8rem 2.4rem;
            
        }

        @media screen and (max-width:600px) {
            padding: 16.1rem 1.2rem;
            row-gap: 0.8rem ;
                
            }

        .title-md {
            text-align: center;

            @media screen and (max-width:800px) {
                font-size: 3.2rem;
            }

            @media screen and (max-width:460px) {
                font-size: 2.4rem;
            }

            @media screen and (max-width:360px) {
                font-size: 2rem;
            }
        }
    }
}

.bt {
    position: relative;
    background: $color-pri;
    overflow: hidden;
    width: 100%;
    height: 100%;

    &::before {
        content: "";
        position: absolute;
        inset: 0;
        background-image: url(../assets/images/khoi.png);
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100% 100%;
        // z-index: 1;
        mix-blend-mode: multiply;
    }
    &_wrap {
        padding: 41.2rem 2.4rem;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: 800px) {
            padding: 37rem 1.2rem;
        }

        @media screen and (max-width: 600px) {
            padding: 33.8rem 1.2rem;
        }

        @media screen and (max-width: 360px) {
            padding: 30rem 1.2rem;
        }

        .title-md {
            text-align: center;
            opacity: 0.5;

            @media screen and (max-width: 800px) {
                font-size: 4rem;
            }

            @media screen and (max-width: 600px) {
                font-size: 3.2rem;
            }

            @media screen and (max-width: 360px) {
                font-size: 2.4rem;
            }
        }
    }
}
