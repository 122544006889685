.sign {
    width: 100%;
    // min-height: calc(100vh - var(--size-hd));
    position: relative;

 

    &_wrap {
        min-height: calc(100vh - var(--size-hd));

        @media screen and (max-width: 1200px) {
            min-height: calc(100vh - var(--size-hd-tl));
        }
    }



    &_lf {
        flex: 1;
        // height: calc(100vw *  896 / 613);
        // height: 100%;
        @media screen and (max-width: 1200px) {
            width: 100%;
        }

        @media screen and (max-width: 600px) {
            width: 100%;
            position: absolute;
            inset: 0;
        }

        &--wrap {
            margin-left: calc((100vw - 112rem) / -2);
            position: relative;
            height: 100%;
            margin-right: -0.6rem;
            display: flex;
            align-items: center;
            justify-content:center;

            @media screen and (max-width: 1200px) {
                margin-left: -4.8rem;
                margin-right: 0;
            }

            .title-sm3 {
                padding-top: 10rem;
                padding-bottom: 10rem;
                position: relative;
                z-index: 2;
                text-align: center;

                // @media screen and (max-width: 1200px) {
                //     padding-top: 26.3rem;
                //     padding-bottom: 26.3rem;
                // }

                @media screen and (max-width: 600px) {
                    display: none;
                }
            }
        }

        &--bg {
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    &_rt {
        width: calc(100% * 7 / 12 + 2.4rem);
        position: relative;
        z-index: 2;
        @media screen and (max-width: 1200px) {
            width: calc(100% * 6 / 12 + 2.4rem);
        }

        @media screen and (max-width: 600px) {
            width: 100%;
        }

        &--wrap {
            display: flex;
            height: 100%;
            width: 100%;
            align-items: center;
            justify-content: end;
            padding-top: 10rem;
            padding-bottom: 10rem;
            @media screen and (max-width: 1200px) {
                justify-content: center;
            }

            // @media screen and (max-width: 600px) {
               
            // }
        }

        &--content {
            max-width: 51rem;
            width: 100%;
            display: flex;
            flex-direction: column;
            row-gap: 1.2rem;

            @media screen and (max-width: 1200px) {
                max-width: none;
            }
            .title-mn {
                display: none;
                text-align: center;
                @media screen and (max-width: 600px) {
                    display: block;
                }
            }

            .note-md {
                text-align: center;

                @media screen and (max-width: 600px) {
                    font-size: 1.6rem;
                    color: $color-white;
                }

                @media screen and (max-width: 360px) {
                    font-size: 1.4rem;
                    color: $color-white;
                }
            }

            .footer-center-bottom-form {
                max-width: none;
            }

            .footer-center-bottom-check .box {
                border-color: $color-gray2;
            }

            .footer-center-bottom-check .note-sm {
                color: $color-text;

                @media screen and (max-width: 800px) {
                    @include fz-12;
                }

                @media screen and (max-width: 600px) {
                    color: $color-white;
                }
            }

            .footer-social {
                justify-content: center;
            }

            .footer-social-link {
                @include hover {
                    img {
                        filter: none;
                    }
                }
            }

            .footer-social-link img {
                filter: brightness(0) saturate(100%) invert(9%) sepia(6%) saturate(6%) hue-rotate(1deg) brightness(104%)
                    contrast(99%);

                @media screen and (max-width: 600px) {
                    filter: brightness(0) saturate(100%) invert(91%) sepia(92%) saturate(31%) hue-rotate(250deg)
                        brightness(106%) contrast(100%);
                }
            }

            .btn {
                margin: auto;
            }
        }
    }
}
