.select2-container {
    height: 4.4rem;
    background-color: transparent;
}

.select2.select2-container {
    width: 100% !important;
    // margin-right: 1rem;
}

.selection {
    width: 100%;
    height: 100%;
    display: block;
}

.select2-container--default .select2-selection--single {
    border: none;
    // border-radius: 0 !important;
    color: white;
    height: 2.4rem;
    background: $color-white;
    border-bottom: 0.2rem solid $color-gray;
    border-radius: 0rem !important;
    display: flex !important;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    display: none;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    position: absolute;

    &::before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        width: 2rem;
        height: 2rem;
        background-image: url(../assets/images/arrDownSl.svg);
        background-repeat: no-repeat;
        background-size: 100%;
    }
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single {
    // border-radius: 0 !important;
}

.select2.select2-container .select2-selection--multiple {
    height: auto;
    min-height: 34px;
}

.select2-container .select2-selection--single .select2-selection__rendered {
    background-color: transparent;
    color: $color-text;
    font-family: "Roboto";
    opacity: 1;
    @include fz-16;
    font-weight: 700;
    display: inline !important;
    @include lc(1);
    width: 100%;
    padding: 0 5rem 0 0rem;
}

.select2.select2-container .select2-selection--multiple .select2-selection__rendered {
    display: block;
    padding: 0 4px;
}

.select2-container .select2-dropdown {
    background: transparent;
    border: none;
    top: 0.8rem;
    border-radius: 0rem;
    overflow: hidden;
    box-shadow: 0 0 0.5rem rgba($color: #000000, $alpha: 0.3);
}

.select2-container .select2-dropdown .select2-search {
    padding: 0;
}

.select2-container .select2-dropdown .select2-search input {
    outline: none !important;
    border: none;
    border-bottom: none !important;
    padding: 10px 6px !important;
    background-color: $color-black;
    color: $color-black;
}

.select2-container .select2-dropdown .select2-results ul {
    background: $color-white;
    color: $color-black;
}

.select2-container .select2-dropdown .select2-results ul .select2-results__option--highlighted[aria-selected] {
    background-color: $color-pri;
    font-size: 1.4rem;
}

.select2-container--default .select2-results__option--selected {
    background-color: transparent;
    color: $color-black;
    font-size: 1.4rem;
    font-weight: 400;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 100%;
    border-color: #000 transparent transparent transparent;
    // top: 1.7rem !important;
    // top: 10%;
    right: 0rem;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #c4bbbc;
}

.select2-search.select2-search--dropdown {
    display: none !important;
}

.select2-results__option {
    font-size: 1.4rem;
    font-weight: 400;
    padding: 0.4rem 1rem;
    font-family: "Roboto";
}

.select2-results {
    display: block;
    border-radius: 0 !important;
    overflow: hidden;
}

.re-select-wrap.white {
    .select2-selection__rendered {
        color: #a5a5a5 !important;
    }
}

// .select2-results__options {
//     .select2-results__option {
//         &:first-child {
//             display: none;
//         }
//     }
// }

body:has(.seclect2-seach-show) {
    .select2-search.select2-search--dropdown {
        display: block !important;
        background: #ffffff;
        padding: 1.2rem 2.4rem 0.4rem;
    }

    .select2-container .select2-dropdown .select2-search input {
        background-color: rgba($color: #d9d9d9, $alpha: 0.2);
    }

    .select2-dropdown {
        box-shadow: 0rem 0.4rem 4rem rgba(0, 0, 0, 0.1);
        margin-top: 2.2rem;
    }
    .select2-results__option {
        padding: 0.4rem 1rem;
        font-size: 1.2rem;
    }
    .select2-container .select2-dropdown .select2-results ul .select2-results__option--highlighted[aria-selected] {
        font-size: 1.2rem;
        line-height: 1.7;
        color: white;
    }
}
