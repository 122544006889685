.find {
    overflow: hidden;
    &_wrap {
        // align-items: center;
    }

    &_lf {
        width: calc(100% * 5 / 12);

        @media screen and (max-width: 1200px) {
            width: calc(100% * 6 / 12);
        }

        @media screen and (max-width: 600px) {
            width: 100%;
        }

        &--map {
            padding-top: 100%;
            position: relative;
            overflow: hidden;
            width: 100%;
            display: none;

            @media screen and (max-width: 600px) {
                display: block;
            }

            iframe {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &--wrap {
            padding-top: var(--size-pdl);
            padding-bottom: var(--size-pdl);
            display: flex;
            flex-direction: column;
            row-gap: 2.4rem;

            @media screen and (max-width: 1200px) {
                padding-top: var(--size-pdl-tl);
                padding-bottom: var(--size-pdl-tl);
                row-gap: 1.6rem;
            }

            @media screen and (max-width: 600px) {
                padding-top: var(--size-pdl-mb);
                padding-bottom: var(--size-pdl-mb);
                row-gap: 0.8rem;
            }
        }

        &--result {
            display: flex;
            flex-direction: column;
            row-gap: 1.2rem;
            &-title {
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 0.1rem solid $color-black;
                flex-wrap: wrap;
                // row-gap: 0.1rem;
                column-gap: 1rem;
                .note-md {
                    font-size: 1.8rem;

                    @media screen and (max-width: 600px) {
                        font-size: 1.6 rem;
                    }
                }



            }

            &-list {
                display: flex;
                flex-direction: column;
                row-gap: 0.8rem;
                max-height: 60vh;
                overflow-y: auto;
                padding-right: 0.25rem;
                @media screen and (max-width: 1200px) {
                    max-height: 44vh;
                }

                @media screen and (max-width: 800px) {
                    max-height: 38vh;
                }

                @media screen and (max-width: 600px) {
                    max-height: unset;
                    overflow-y: auto;
                    padding-right: 0;
                }
            }

            &-item {
                display: flex;
                flex-direction: column;
                row-gap: 0.8rem;
                padding: 0.8rem 1.2rem;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
                cursor: pointer;
                transition: all 0.3s linear;
                background: $color-white;

                @include hover {
                    background: $color-second;

                    .note-sm {
                        border-color: $color-pri;
                    }
                }

                &.actived{
                    background: $color-second;

                    .note-sm {
                        border-color: $color-pri;
                    }
                }

                @media screen and (max-width: 800px) {
                    row-gap: 0.4rem;
                }

                .note-sm {
                    padding-bottom: 0.8rem;
                    border-bottom: 0.1rem solid $color-gray2;
                    transition: all 0.3s linear;
                    @media screen and (max-width: 800px) {
                        @include fz-12;
                        padding-bottom: 0.6rem;
                    }
                }

                .list-address {
                    display: flex;
                    flex-direction: column;
                    row-gap: 0.5rem;
                }

                .item-address {
                    display: flex;
                    column-gap: 1.2rem;
                    // align-items: center;

                    &:nth-child(3) {
                        .link-address {
                            text-decoration: underline;
                        }
                    }

                    @include hover {
                        .ic-address {
                            img {
                                transform: scale(1.15);
                            }
                        }

                        // .link-address {
                        //     transform: scale(1.1);
                        // }
                    }
                }

                .link-address {
                    transition: none;
                    @include fz-14;
                    font-family: var(--font-sec);
                    width: 100%;
                    flex: 1;
                    color: $color-pri;

                    @media screen and (max-width: 800px) {
                        @include fz-12;
                    }
                }

                .ic-address {
                    width: 2rem;
                    // height: 100%;
                    flex-shrink: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    @media screen and (max-width: 800px) {
                        width: 1.6rem;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        filter: brightness(0) saturate(100%) invert(11%) sepia(1%) saturate(518%) hue-rotate(342deg)
                            brightness(96%) contrast(103%);
                            transition: all 0.3s ease-in-out;
                    }
                }
            }
        }

        &--label {
            @include fz-12;
            font-family: var(--font-sec);
            text-transform: uppercase;
            font-weight: 600;
        }

        &--box {
            display: flex;
            flex-direction: column;
            row-gap: 0.8rem;
        }

        &--ip {
            display: flex;
            input {
                width: 100%;
                padding: 0.8rem 1.2rem;
                background: $color-gray2;

                &::placeholder {
                    color: $color-gray;
                }
            }

            .btn {
                width: fit-content;
                min-width: unset;
                padding: 1.2rem 2rem;
            }
        }
    }

    &_rt {
        flex: 1;

        @media screen and (max-width: 1200px) {
            width: 100%;
        }

        @media screen and (max-width: 600px) {
            display: none;
        }

        &--wrap {
            margin-right: calc((100vw - 112rem) / -2);
            height: 100%;

            @media screen and (max-width: 1200px) {
                margin-right: -3rem;
            }
        }

        &--map {
            padding-top: calc(100% * 600 / 803);
            width: 100%;
            position: relative;
            overflow: hidden;
            height: 100%;
            iframe {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}
