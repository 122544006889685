@mixin fz-200 {
    font-size: 20rem;

    @media only screen and (max-width: 1200px) {
        font-size: 16rem;
    }

    @media only screen and (max-width: 800px) {
        font-size: 13rem;
    }

    @media only screen and (max-width: 600px) {
        font-size: 10rem;
    }

    @media only screen and (max-width: 460px) {
        font-size: 8rem;
    }
}

@mixin fz-180 {
    font-size: 18rem;

    @media only screen and (max-width: 1200px) {
        font-size:16rem;
    }

    @media only screen and (max-width: 800px) {
        font-size: 15rem;
    }

    @media only screen and (max-width: 600px) {
        font-size: 14rem;
    }

    @media only screen and (max-width: 460px) {
        font-size: 9rem;
    }
}

@mixin fz-160 {
    font-size: 16rem;

    @media only screen and (max-width: 1200px) {
        font-size:14rem;
    }

    @media only screen and (max-width: 800px) {
        font-size: 14rem;
    }

    @media only screen and (max-width: 600px) {
        font-size: 13rem;
    }

    @media only screen and (max-width: 460px) {
        font-size: 8rem;
    }
}

@mixin fz-140 {
    font-size: 14rem;

    @media only screen and (max-width: 1200px) {
        font-size:13rem;
    }

    @media only screen and (max-width: 800px) {
        font-size: 12rem;
    }

    @media only screen and (max-width: 600px) {
        font-size: 10rem;
    }

    @media only screen and (max-width: 460px) {
        font-size: 6rem;
    }
}

@mixin fz-120 {
    font-size: 12rem;

    @media only screen and (max-width: 1200px) {
        font-size:11rem;
    }

    @media only screen and (max-width: 800px) {
        font-size: 10rem;
    }

    @media only screen and (max-width: 600px) {
        font-size: 9rem;
    }

    @media only screen and (max-width: 460px) {
        font-size: 7rem;
    }
}

@mixin fz-100 {
    font-size: 10rem;

    @media only screen and (max-width: 1200px) {
        font-size: 9rem;
    }

    @media only screen and (max-width: 800px) {
        font-size: 8rem;
    }

    @media only screen and (max-width: 600px) {
        font-size: 7rem;
    }

    @media only screen and (max-width: 460px) {
        font-size: 6rem;
    }

    
}

@mixin fz-98 {
    font-size: 9.8rem;

    @media only screen and (max-width: 1200px) {
        font-size: 8.8rem;
    }

    @media only screen and (max-width: 800px) {
        font-size: 7.8rem;
    }

    @media only screen and (max-width: 600px) {
        font-size: 6.8rem;
    }

    
}

@mixin fz-96 {
    font-size: 9.6rem;

    @media only screen and (max-width: 1200px) {
        font-size: 8.6rem;
    }

    @media only screen and (max-width: 800px) {
        font-size: 7.6rem;
    }

    @media only screen and (max-width: 600px) {
        font-size: 6.6rem;
    }

    @media only screen and (max-width: 500px) {
        font-size: 4.2rem;
    }

    @media only screen and (max-width: 400px) {
        font-size: 3.6rem;
    }
}

@mixin fz-94 {
    font-size: 9.4rem;

    @media only screen and (max-width: 1200px) {
        font-size: 8.4rem;
    }

    @media only screen and (max-width: 800px) {
        font-size: 7.4rem;
    }

    @media only screen and (max-width: 600px) {
        font-size: 6.4rem;
    }

    
}



@mixin fz-92 {
    font-size: 9.2rem;

    @media only screen and (max-width: 1200px) {
        font-size: 8.2rem;
    }

    @media only screen and (max-width: 800px) {
        font-size: 7.2rem;
    }

    @media only screen and (max-width: 600px) {
        font-size: 6.2rem;
    }

    
}



@mixin fz-88 {
    font-size: 8.8rem;

    @media only screen and (max-width: 1200px) {
        font-size: 7.8rem;
    }

    @media only screen and (max-width: 800px) {
        font-size: 6.8rem;
    }

    @media only screen and (max-width: 600px) {
        font-size: 5.8rem;
    }

    
}



@mixin fz-86 {
    font-size: 8.6rem;

    @media only screen and (max-width: 1200px) {
        font-size: 7.6rem;
    }

    @media only screen and (max-width: 800px) {
        font-size: 6.6rem;
    }

    @media only screen and (max-width: 600px) {
        font-size: 5.6rem;
    }

    
}



@mixin fz-84 {
    font-size: 8.4rem;

    @media only screen and (max-width: 1200px) {
        font-size: 7.4rem;
    }

    @media only screen and (max-width: 800px) {
        font-size: 6.4rem;
    }

    @media only screen and (max-width: 600px) {
        font-size: 5.4rem;
    }

    
}




@mixin fz-82 {
    font-size: 8.2rem;

    @media only screen and (max-width: 1200px) {
        font-size: 7.2rem;
    }

    @media only screen and (max-width: 800px) {
        font-size: 6.2rem;
    }

    @media only screen and (max-width: 600px) {
        font-size: 5.2rem;
    }

    
}


@mixin fz-80 {
    font-size: 8rem;

    @media only screen and (max-width: 1200px) {
        font-size: 5rem;
    }

    @media only screen and (max-width: 800px) {
        font-size: 4.5rem;
    }

    @media only screen and (max-width: 600px) {
        font-size: 4rem;
        line-height: 1.5 !important;

    }

    @media only screen and (max-width: 460px) {
        font-size: 3rem;
        line-height: 1.5 !important;
    }

    
}

@mixin fz-78 {
    font-size: 7.8rem;

    @media only screen and (max-width: 1200px) {
        font-size: 5.8rem;
    }

    @media only screen and (max-width: 800px) {
        font-size: 4.8rem;
    }

    @media only screen and (max-width: 600px) {
        font-size: 3.8rem;
    }

    
}

@mixin fz-76 {
    font-size: 7.6rem;

    @media only screen and (max-width: 1200px) {
        font-size: 5.6rem;
    }

    @media only screen and (max-width: 800px) {
        font-size: 4.6rem;
    }

    @media only screen and (max-width: 600px) {
        font-size: 3.6rem;
    }

    
}

@mixin fz-74 {
    font-size: 7.4rem;

    @media only screen and (max-width: 1200px) {
        font-size: 6.4rem;
    }

    @media only screen and (max-width: 800px) {
        font-size: 5.4rem;
    }

    @media only screen and (max-width: 600px) {
        font-size: 4.4rem;
    }

    
}

@mixin fz-70 {
    font-size: 7rem;

    @media only screen and (max-width: 1200px) {
        font-size: 6rem;
    }

    @media only screen and (max-width: 800px) {
        font-size: 5rem;
    }

    @media only screen and (max-width: 600px) {
        font-size: 4rem;
    }

    
}


@mixin fz-68 {
    font-size: 6.8rem;

    @media only screen and (max-width: 1200px) {
        font-size: 5.8rem;
    }

    @media only screen and (max-width: 800px) {
        font-size: 4.8rem;
    }

    @media only screen and (max-width: 600px) {
        font-size: 3.8rem;
    }

    
}

@mixin fz-66 {
    font-size: 6.6rem;

    @media only screen and (max-width: 1200px) {
        font-size: 5.6rem;
    }

    @media only screen and (max-width: 800px) {
        font-size: 4.6rem;
    }

    @media only screen and (max-width: 600px) {
        font-size: 3.6rem;
    }

    
}


@mixin fz-64 {
    font-size: 6.4rem;

    @media only screen and (max-width: 1200px) {
        font-size: 5.4rem;
    }

    @media only screen and (max-width: 800px) {
        font-size: 4.4rem;
    }

    @media only screen and (max-width: 600px) {
        font-size: 3rem !important;
    }

    
}


@mixin fz-62 {
    font-size: 6.2rem;

    @media only screen and (max-width: 1200px) {
        font-size: 5.2rem;
    }

    @media only screen and (max-width: 800px) {
        font-size: 4.2rem;
    }

    @media only screen and (max-width: 600px) {
        font-size: 3.2rem;
    }

    
}

@mixin fz-58 {
    font-size: 5.8rem;

    @media only screen and (max-width: 1200px) {
        font-size: 4.8rem;
    }

    @media only screen and (max-width: 800px) {
        font-size: 3.8rem;
    }

    @media only screen and (max-width: 600px) {
        font-size: 2.8rem;
    }
}

@mixin fz-54 {
    font-size: 5.4rem;

    @media only screen and (max-width: 1200px) {
        font-size: 4.4rem;
    }

    @media only screen and (max-width: 800px) {
        font-size: 3.4rem;
    }

    @media only screen and (max-width: 600px) {
        font-size: 2.4rem;
    }
}

@mixin fz-52 {
    font-size: 5.2rem;

    @media only screen and (max-width: 1200px) {
        font-size: 4.2rem;
    }

    @media only screen and (max-width: 800px) {
        font-size: 3.2rem;
    }

    @media only screen and (max-width: 600px) {
        font-size: 2.2rem;
    }

   
}

@mixin fz-46 {
    font-size: 4.6rem;

    @media only screen and (max-width: 1200px) {
        font-size: 3.6rem;
    }

    @media only screen and (max-width: 800px) {
        font-size: 2.6rem;
    }
    @media only screen and (max-width: 600px) {
        font-size: 1.6rem;
    }

   
}

@mixin fz-44 {
    font-size: 4.4rem;

    @media only screen and (max-width: 1200px) {
        font-size: 3.4rem;
    }

    @media only screen and (max-width: 800px) {
        font-size: 2.4rem;
    }

    @media only screen and (max-width: 600px) {
        font-size: 1.4rem;
    }

   
}

@mixin fz-90 {
    font-size: 9rem;

    @media only screen and (max-width: 1200px) {
        font-size: 8rem;
    }

    @media only screen and (max-width: 800px) {
        font-size: 7rem;
    }

    @media only screen and (max-width: 600px) {
        font-size: 6rem;
    }

    
}

@mixin fz-72 {
    font-size: 7.2rem;

    @media only screen and (max-width: 1200px) {
        font-size: 6.2rem;
    }

    @media only screen and (max-width: 800px) {
        font-size: 5.2rem;
    }

    @media only screen and (max-width: 600px) {
        font-size: 4.2rem;
    }

   
}

@mixin fz-65 {
    font-size: 6.5rem;

    @media only screen and (max-width: 1200px) {
        font-size: 5.5rem;
    }

    @media only screen and (max-width: 800px) {
        font-size: 4.5rem;
    }

    @media only screen and (max-width: 600px) {
        font-size: 3.5rem;
    }
}

@mixin fz-64 {
    font-size: 6.4rem;

    @media only screen and (max-width: 1200px) {
        font-size: 5.4rem;
    }

    @media only screen and (max-width: 800px) {
        font-size: 4.4rem;
    }

    @media only screen and (max-width: 600px) {
        font-size: 3.4rem;
    }

    @media only screen and (max-width: 460px) {
        font-size: 3rem;
    }
}

@mixin fz-60 {
    font-size: 6rem;

    @media only screen and (max-width: 1200px) {
        font-size: 5.5rem;
    }

    @media only screen and (max-width: 800px) {
        font-size: 4.5rem;
    }

    @media only screen and (max-width: 600px) {
        font-size: 3.5rem;
    }
}

@mixin fz-56 {
    font-size: 5.6rem;

    @media only screen and (max-width: 1200px) {
        font-size: 4.6rem;
    }

    @media only screen and (max-width: 800px) {
        font-size: 3.6rem;
    }

    @media only screen and (max-width: 600px) {
        font-size: 2.6rem;
    }
}

@mixin fz-55 {
    font-size: 5.5rem;

    @media only screen and (max-width: 1200px) {
        font-size: 4.5rem;
    }

    @media only screen and (max-width: 800px) {
        font-size: 3.5rem;
    }

    @media only screen and (max-width: 600px) {
        font-size: 2.5rem;
    }
}

@mixin fz-50 {
    font-size: 5rem;

    @media only screen and (max-width: 1200px) {
        font-size: 4rem;
    }

    @media only screen and (max-width: 800px) {
        font-size: 3rem;
    }

    @media only screen and (max-width: 600px) {
        font-size: 2rem;
    }
}

@mixin fz-48 {
    font-size: 4.8rem;

    @media only screen and (max-width: 1200px) {
        font-size: 3.8rem;
    }

    @media only screen and (max-width: 800px) {
        font-size: 2.8rem;
    }

    @media only screen and (max-width: 300px) {
        font-size: 2.4rem;
    }

  
}

@mixin fz-45 {
    font-size: 4.5rem;

    @media only screen and (max-width: 1200px) {
        font-size: 3.5rem;
    }

    @media only screen and (max-width: 800px) {
        font-size: 2.5rem;
    }

   
}

@mixin fz-42 {
    font-size: 4.2rem;

    @media only screen and (max-width: 1200px) {
        font-size: 3.2rem;
    }

    @media only screen and (max-width: 800px) {
        font-size: 2.2rem;
    }

   
}

@mixin fz-40 {
    font-size: 4rem;

    @media only screen and (max-width: 1200px) {
        font-size: 3.2rem;
    }

    @media only screen and (max-width: 600px) {
        font-size: 2.4rem;
    }
}

@mixin fz-38 {
    font-size: 3.8rem;

    @media only screen and (max-width: 1200px) {
        font-size: 2.8rem;
    }

    @media only screen and (max-width: 800px) {
        font-size: 2.4rem;
    }
}

@mixin fz-36 {
    font-size: 3.6rem;

    @media only screen and (max-width: 1200px) {
        font-size: 3.2rem;
    }

    @media only screen and (max-width: 600px) {
        font-size: 2rem;
    }
}

@mixin fz-35 {
    font-size: 3.5rem;

    @media only screen and (max-width: 1200px) {
        font-size: 2.5rem;
    }

    // @media only screen and (max-width: 800px) {
    //     font-size: 2.5rem;
    // }
}

@mixin fz-34 {
    font-size: 3.4rem;

    @media only screen and (max-width: 1200px) {
        font-size: 2.4rem;
    }

    // @media only screen and (max-width: 800px) {
    //     font-size: 2.4rem;
    // }
}

@mixin fz-32 {
    font-size: 3.2rem;

    @media only screen and (max-width: 1200px) {
        font-size: 2.2rem;
    }

    @media only screen and (max-width: 600px) {
        font-size: 2rem;
    }
}

@mixin fz-30 {
    font-size: 3rem;

    @media only screen and (max-width: 1200px) {
        font-size: 2rem;
    }

    // @media only screen and (max-width: 800px) {
    //     font-size: 2.4rem;
    // }
}

@mixin fz-29 {
    font-size: 2.9rem;

    @media only screen and (max-width: 1200px) {
        font-size:2.6rem;
    }

    @media only screen and (max-width: 800px) {
        font-size: 1.9rem;
    }
}

@mixin fz-28 {
    font-size: 2.8rem;

    @media only screen and (max-width: 1200px) {
        font-size: 2.4rem;
    }

    @media only screen and (max-width: 800px) {
        font-size: 1.8rem;
    }
}

@mixin fz-26 {
    font-size: 2.6rem;

    @media only screen and (max-width: 1200px) {
        font-size: 2.2rem;
    }

    @media only screen and (max-width: 800px) {
        font-size: 1.6rem;
    }
}

@mixin fz-25 {
    font-size: 2.5rem;

    @media only screen and (max-width: 1200px) {
        font-size: 2.1rem;
    }

    @media only screen and (max-width: 800px) {
        font-size: 1.5rem;
    }
}

@mixin fz-24 {
    font-size: 2.4rem;

    @media only screen and (max-width: 1200px) {
        font-size: 2.2rem;
    }

    // @media only screen and (max-width: 800px) {
    //     font-size: 1.8rem;
    // }
}

@mixin fz-22 {
    font-size: 2.2rem;

    @media only screen and (max-width: 1200px) {
        font-size: 2rem;
    }

    @media only screen and (max-width: 800px) {
        font-size: 1.9rem;
    }
}

@mixin fz-20 {
    font-size: 2rem;

    @media only screen and (max-width: 1200px) {
        font-size: 1.8rem;
    }

    // @media only screen and (max-width: 800px) {
    //     font-size: 1.6rem;
    // }
}

@mixin fz-19 {
    font-size: 1.9rem;

    @media only screen and (max-width: 1200px) {
        font-size: 1.7rem;
    }

    @media only screen and (max-width: 800px) {
        font-size: 1.6rem;
    }
}

@mixin fz-18 {
    font-size: 1.8rem;

    @media only screen and (max-width: 1200px) {
        font-size: 1.6rem;
    }

    @media only screen and (max-width: 800px) {
        font-size: 1.4rem;
    }

    @media only screen and (max-width: 600px) {
        font-size: 1.2rem;
    }

    // @media only screen and (max-width: 500px) {
    //     font-size: 1.2rem;
    // }
}

@mixin fz-17 {
    font-size: 1.7rem;
}

@mixin fz-16 {
    font-size: 1.6rem;
}

@mixin fz-15 {
    font-size: 1.5rem;
}

@mixin fz-14 {
    font-size: 1.4rem;

    @media only screen and (max-width: 600px) {
        font-size: 1.2rem;
    }
}

@mixin fz-13 {
    font-size: 1.3rem;
}

@mixin fz-12 {
    font-size: 1.2rem;
}

@mixin fz-11 {
    font-size: 1.1rem;
}

@mixin fz-10 {
    font-size: 1rem;
}

@mixin fz-9 {
    font-size: 0.9rem;
}