// Library
@import url("../assets/library/fontawesome/css/all.css");
@import url("../assets/library/fontawesome/fontawesome/fontawesome.css");
@import url("../assets/library/aos/aos.css");
@import url("../assets/library/swiper/swiper-bundle.min.css");
@import url("../assets/library/themify-icons/themify-icons.css");
@import url("../assets/library/select2/select2.min.css");
@import url("../assets/library/themifi/themify-icons.css");
@import url("../assets/library/gallery/lightgallery.min.css");
@import url("../assets/library/fancybox/fancybox.css");
@import url("../assets/library/magnific/magnific-popup.css");
@import url("../assets/library/plyr/plyr.css");
@import url("../assets/library/scrollFullPage/fullPage.css");
@import url("../assets/library/magnify/magnify.css");






// Core
@import "./core/reset";
@import "./core/mona";
@import "./core/mixin";
@import "./core/variable";
@import "./core/font";
@import "./core/base";
@import "./core/flex";
@import "./core/global";
@import "./core/animation";




// Component
@import "./components/header";
@import "./components/footer";
@import "./components/gallery";
@import "./components/popup";
@import "./components/select2";
@import "./components/smenu";
@import "./components/breadcrumb";
@import "./components/backtotop";
@import "./components/aos";
@import "./components/banner";
@import "./components/linksMain";
@import "./components/doubleRange";
@import "./components/loading";











// Layout
@import "./layouts/btn";
@import "./layouts/component";
@import "./layouts/pagi";
@import "./layouts/distance";
@import "./layouts/text";




// Pages

@import "./pages/product";
@import "./pages/findStore";
@import "./pages/post";
@import "./pages/contact";
@import "./pages/sign";
@import "./pages/cs";
@import "./pages/about";
@import "./pages/home";
@import "./pages/qt";































