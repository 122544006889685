:root {
    --size-hd: 12.8rem;
    --size-hd-tl: 6.4rem;

    --size-pdl: 4rem;
    --size-pdl-tl: 2.4rem;
    --size-pdl-mb: 1.6rem;
    --size-pd: 2rem;
    --size-pd-tl: 1.2rem;
    --size-pd-mb: 0.8rem;
    --font-pri:  "Arial";
    --font-sec: "SVN-Segoe UI";
}

// dit me m nho chua
$color-pri: #181818;
$color-second: #FEBE10;
$color-third: #f0fdfe;

$color-four: #e2e8f0;
$color-five: #f1f5f9;
$color-six: #1e293b;

$color-text: #181818;
$color-text2: #8c8284;
$color-text3: #334155;

$color-black-2: #444;
$color-black-3: #696061;
$color-black: #000000;

$color-white: #ffffff;
$color-white2: #f7f5f5;
$color-gray: #999;
$color-gray2: #E6E6E6;
$color-gray3: #94a3b8;
$color-gray4: #64748b;

//Responsive
$res-mobile: 1200px;
$res-mobile-min: 1201px;
$mobile: 460px;
